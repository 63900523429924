import * as _ from "underscore"; 

import {INotificationService} from '../../common/services/INotificationService';
import {IAppSettings} from '../../common/configs/IAppSettings';

class DataService {
    static $inject = ["$http", "notification.service", "appSettings", "$log", "$q", "authentication.service", "$window", "facility.service", "promise.service"];
    constructor(
        // Add the parameter and type definition.
        private readonly $http: ng.IHttpService,
        private readonly notification: INotificationService,
        private readonly appSettings: IAppSettings,
        private readonly $log: any,
        private readonly $q: ng.IQService,
        private readonly authenticationService: any,
        private readonly $window: ng.IWindowService,
        private readonly facilityService: any,
        private readonly promiseService: any
    ) {
    } 
    lastRequest: any;

    successCallback = (response: any): any => {
        return response.data;
    } 
    
    flattenModelStateErrors = (response : any) : any => {
        if (response.data && response.data.modelState) {
            var errors = {};
            _.each(response.data.modelState, (value : string[], property) => {
                errors[property] = value.join(", ");
            });
            response.modelStateSummary = errors;
        }
    }

    errorCallback = (response: any): any => {
        this.$log.debug(response);
        if (response && response.status === 401) {
            this.$window.alert("A request failed because it lacks valid authentication credentials, log in is required.");
            this.$window.location.reload();
        }
        this.$log.error(response.data || "Unexpected error with empty body.");
        this.flattenModelStateErrors(response);
        return this.$q.reject(response);
    }

    finallyCallback = (hideLoadingNotification: boolean = false) : any => {
        if (hideLoadingNotification) {
            return;
        }
        this.notification.stopLoading();
    }

    beforeRequest = (hideLoadingNotification: boolean = false) : void => {
        if (hideLoadingNotification) {
            return;
        }
        this.notification.startLoading();
    }

    getHttpConfig = () : any => {
        return this.authenticationService.getUser().then(user => {
            var config = {
                headers: {
                    Authorization: null
                }
            };
            if (user && user.access_token) {
                config.headers.Authorization = user.token_type + " " + user.access_token;
            }
            return config;
        });
    }

    getSelectedFacilityId = () : string => {
        return this.facilityService.selectedFacility.id;
    }

    addSelectedFacilityId = (resource: any) : string => {
        if (!this.facilityService.selectedFacility.id) {
            return resource;
        }

        if (resource.indexOf("?") > -1) {
            return resource + "&selectedFacilityId=" + this.facilityService.selectedFacility.id;
        } else {
            return resource + "?selectedFacilityId=" + this.facilityService.selectedFacility.id;
        }
    }

    get = (resource : string, hideLoadingNotification : boolean, parameters : any, ignoreSelectedFacility : boolean) : any => {
        this.beforeRequest(hideLoadingNotification);
        if (!ignoreSelectedFacility) {
            resource = this.addSelectedFacilityId(resource);
        };

        return this.getHttpConfig().then((config) => {
            if (parameters) {
                config.params = parameters;
            };

            return this.$http.get(this.appSettings.ApiUrl + resource, config)
                .then(this.successCallback, this.errorCallback)
                .finally(() => this.finallyCallback(hideLoadingNotification));
        });
    }

    getWithCancellationRequest = (resource: string, hideLoadingNotification: boolean, parameters: any, ignoreSelectedFacility: boolean): any => {
        this.beforeRequest(hideLoadingNotification);
        if (!ignoreSelectedFacility) {
            resource = this.addSelectedFacilityId(resource);
        };

        this.promiseService.cancelPromise(this.lastRequest);

        return this.getHttpConfig().then((config) => {
            if (parameters) {
                config.params = parameters;
            };
            const httpRequestCanceller = this.$q.defer();
            var promise: any;

            this.lastRequest = promise = this.$http({
                url: this.appSettings.ApiUrl + resource,
                method: 'GET',
                headers: config.headers,
                timeout: httpRequestCanceller.promise,
                params: config.params
            });

            promise.cancel = () => {
                httpRequestCanceller.resolve();
            }

            this.promiseService.register(promise);

            return promise.then((report) => {
                this.promiseService.unRegister(promise);
                return report.data;
            });
        });
    }

    getAnonymous = (resource: string, hideLoadingNotification: boolean, parameters: any): any => {
        this.beforeRequest(hideLoadingNotification);
        return this.getHttpConfig().then((config) => {
            if (parameters) {
                config.params = parameters;
            };

            return this.$http.get(this.appSettings.ApiUrl + resource, config)
                .then(this.successCallback, this.errorCallback)
                .finally(() => this.finallyCallback(hideLoadingNotification));
        });
    }

    getArrayBuffer = (resource: string, hideLoadingNotification: boolean, parameters: any, ignoreSelectedFacility: boolean): any => {
        this.beforeRequest(hideLoadingNotification);
        if (!ignoreSelectedFacility) {
            resource = this.addSelectedFacilityId(resource);
        };

        return this.getHttpConfig().then((config) => {
            if (parameters) {
                config.params = parameters;
            };
            config.responseType = 'arraybuffer';
            return this.$http.get(this.appSettings.ApiUrl + resource, config)
                .then(this.successCallback, this.errorCallback)
                .finally(() => this.finallyCallback(hideLoadingNotification));
        });
    }

    post = (resource : string, data : any, hideLoadingNotification: boolean = false) : any => {
        this.beforeRequest(hideLoadingNotification);
        resource = this.addSelectedFacilityId(resource);
        return this.getHttpConfig().then((config) => {
            return this.$http.post(this.appSettings.ApiUrl + resource, data, config)
                .then(this.successCallback, this.errorCallback)
                .finally(() => this.finallyCallback(hideLoadingNotification));
        });
    }    

    postAnonymous = (resource: string, data: any): any => {
      this.beforeRequest();
      return this.getHttpConfig().then((config) => {
        return this.$http.post(this.appSettings.ApiUrl + resource, data, config)
          .then(this.successCallback, this.errorCallback)
          .finally(this.finallyCallback);
      });
    }

    put = (resource : string, data: any, hideLoadingNotification: boolean = false) : any => {
        this.beforeRequest(hideLoadingNotification);
        resource = this.addSelectedFacilityId(resource);
        return this.getHttpConfig().then((config) => {
            return this.$http.put(this.appSettings.ApiUrl + resource, data, config)
                .then(this.successCallback, this.errorCallback)
                .finally(() => this.finallyCallback(hideLoadingNotification));
        });
    }

    "delete" = (resource : string, hideLoadingNotification: boolean = false) : any => {
        this.beforeRequest(hideLoadingNotification);
        resource = this.addSelectedFacilityId(resource);
        return this.getHttpConfig().then((config) => {
            return this.$http.delete(this.appSettings.ApiUrl + resource, config)
                .then(this.successCallback, this.errorCallback)
                .finally(() => this.finallyCallback(hideLoadingNotification));
        });
    }
}
 
export = DataService;
