"use strict";
const _ = require("underscore");
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

module.exports = [
  "$scope",
  "residents.service",
  "leads.service",
  "organisation.facility.service",
  "notification.service",
  "$stateParams",
  "$state",
  "dialog.service",
  "$q",
  "$location",
  "$anchorScroll",
  "$timeout",
  "$filter",
  "EffectiveDateTypes",
  "$rootScope",
  "featureFlags.service",
  "utilities.service",
  "referenceData.service",
  function (
    $scope,
    residentsService,
    leadsService,
    organisationFacilityService,
    notification,
    $stateParams,
    $state,
    dialogService,
    $q,
    $location,
    $anchorScroll,
    $timeout,
    $filter,
    EffectiveDateTypes,
    $rootScope,
    featureFlagsService,
    utilitiesService,
    referenceDataService
  ) {
    var vm = this;
    // Attach form to vm after the form is initialized
    $scope.$watch("saveFinanceForm", function (form) {
      if (form) {
        vm.saveFinanceForm = form; // Attach the form to vm
      }
    });
    var residentId = $scope.residentId;
    vm.disableSendingExtraServiceToMedicare = featureFlagsService.featureFlags.systemConfigurationFlags.disableSendingExtraServiceToMedicare;
    this.isPrivateResidency = $stateParams.isPrivateResidency;
    var stateViewResidentFinancialsFees = "viewResident.financialsFees";
    var stateViewLeadFinancialsFees = "viewLead.financialsFees";
    vm.EffectiveDateTypes = EffectiveDateTypes;
    vm.billInvoiceMethod = null;
    vm.billPayers = [];
    vm.selectedBillPayer = null;
    vm.facilityOptionalServices = [];
    vm.facilityServicesDropDownOptions = [];
    vm.triggerUniqueValidation = false;
    vm.dropdownSettings = {
      singleSelection: true,
      addAllOption: false,
      enableCheckAll: false,
      showClearSelectedItems: false,
    };
    vm.optionalServices = [];
    vm.setBasicDailyFeeToZero = false;
    vm.existingBillPayer = vm.selectedBillPayer;
    vm.isCheckboxTouched = false;
    vm.countries = [];
    vm.contact = {};
    vm.ccEmailDelimiter = ";";
    vm.ccIndex = 0;
    vm.emailAddresses = [{ name: "email", value: "" }];
    vm.invalidCCEmailLengthDisplayed = false;
    vm.ccEmails = "";
    vm.ccEmailLengthLimit = 150;

    vm.showDropDown = function (optionalService) {
      return true;
    };

    vm.serviceOptionalServiceIdChanged = function (optionalService, ctrl) {
      if (ctrl.$modelValue) {
        var selectedItemId = ctrl.$modelValue[0].id;
        optionalService.optionalServiceId = selectedItemId;
        var facilityOptionalService = vm.facilityOptionalServices.find((x) => x.id == selectedItemId);
        optionalService.facilityOptionalServiceStartDateUtc = facilityOptionalService.startDateUtc;
        optionalService.facilityOptionalServiceEndDateUtc = facilityOptionalService.endDateUtc;
        ctrl.$setValidity("required", true);
      } else {
        ctrl.$setValidity("required", false);
      }
    };

    vm.onBillPayerSelected = function (item) {
      if (!item.exists) {
        dialogService.openDialog("app/residents/partials/addContactDialog.html", "addContactDialog.controller", {
          resident: vm.resident,
          payerType: vm.selectedBillPayer.payerType,
          payerTypeName: vm.selectedBillPayer.payerTypeName,
          state: $state.current.name,
          callBack: vm.setContact,
          persist: true,
        });
      }
      vm.populateCcEmailAddresses();
      vm.contact = vm.selectedBillPayer.contact;
      resetIsInvoiceEmailIfNoEmail();
      resetIsInvoicePostIfNoPostalAddress();
      setInvoiceMethodSelectedFlag();

      vm.finance.drawdownDAC = false;
      vm.finance.drawdownBDF = false;
      vm.finance.drawdownMTCF = false;
      vm.finance.drawdownAC = false;
      vm.finance.drawdownPI = false;
      vm.finance.drawdownITF = false;
    };

    vm.addEditFeeEffectiveDatesDialog = function (effectiveDateType) {
      dialogService.openDialog("app/residents/partials/addEditFeeEffectiveDatesDialog.html", "addEditFeeEffectiveDatesDialog.controller", {
        effectiveDateType: effectiveDateType,
        effectiveDates: _.filter(vm.finance.effectiveDates, function (item) {
          return item.effectiveDateType === effectiveDateType;
        }),
        callBack: vm.setEffectiveDates,
        isAdmitted: vm.resident.isAdmitted,
        admissionDate: vm.resident.admissionDateUtc,
        isEmptyCollectionValid: false,
        isFirstValueBasedOnAdmissionDate: true,
      });
    };

    vm.addEditAddress = function () {
      dialogService.openDialog("app/residents/partials/addEditContactAddressDialog.html", "addEditContactDetailDialog.controller", {
        resident: vm.resident,
        payerType: vm.selectedBillPayer.payerType,
        payerTypeName: vm.selectedBillPayer.payerTypeName,
        contactField: "address",
        state: $state.current.name,
        callBack: vm.setContactEmailAddress,
        persist: true,
      });
    };

    vm.setContact = function (payerType, contact) {
      if (payerType === "PrimaryContact") {
        vm.resident.primaryContact = contact;
      } else {
        vm.resident.secondaryContact = contact;
      }

      var billPayer = _.find(vm.billPayers, { payerType: payerType });
      billPayer.name = contact.firstName + " " + contact.lastName;
      billPayer.contact = contact;
      billPayer.exists = true;
    };

    vm.setEffectiveDates = function (effectiveDateType, effectiveDates) {
      // remove all effective dates of the specified type
      var newEffectiveDates = _.filter(vm.finance.effectiveDates, function (item) {
        return item.effectiveDateType !== effectiveDateType;
      });
      // now add new set specified by user
      _.each(effectiveDates, function (item) {
        newEffectiveDates.push(item);
      });

      // make sure effective dates exist for values that are relevant for the corresponding support level,
      // effective from the point where the support level becomes relevant
      if (effectiveDateType === "SupportLevel") {
        _.each(effectiveDates, function (effectiveDate) {
          if (effectiveDate.value === "Supported") {
            var matchingEffectiveDate = _.filter(newEffectiveDates, function (findEffectiveDate) {
              return findEffectiveDate.effectiveDateType == "DailyAccommodationContribution" && moment(findEffectiveDate.effectiveFromDate).isSame(effectiveDate.effectiveFromDate);
            });
            if (matchingEffectiveDate.length === 0) {
              newEffectiveDates.push({
                effectiveDateType: "DailyAccommodationContribution",
                effectiveFromDate: effectiveDate.effectiveFromDate,
                value: "0",
              });
            }
          } else if (effectiveDate.value === "Unsupported") {
            var matchingEffectiveDate = _.filter(newEffectiveDates, function (findEffectiveDate) {
              return findEffectiveDate.effectiveDateType === "AgreedRoomPrice" && moment(findEffectiveDate.effectiveFromDate).isSame(effectiveDate.effectiveFromDate);
            });
            if (matchingEffectiveDate.length === 0) {
              newEffectiveDates.push({
                effectiveDateType: "AgreedRoomPrice",
                effectiveFromDate: effectiveDate.effectiveFromDate,
                value: "0",
              });
            }
          }
        });
      }

      vm.finance.effectiveDates = newEffectiveDates;
      if (effectiveDateType === "RadPaid") vm.setRADPaid();
      if (effectiveDateType === "RacPaid") vm.setRACPaid();
    };

    vm.setRADPaid = function () {
      if ($rootScope.isBillingIntegratedFacility) return;
      //Todo: _filter and _foreach can be replaced with _.Chain
      var radEffectiveDates = _.filter(vm.finance.effectiveDates, (e) => e.effectiveDateType == "RadPaid");
      var totaldap = 0.0;
      if (radEffectiveDates.length > 0) {
        _.each(radEffectiveDates, function (item) {
          totaldap = totaldap + parseFloat(item.value);
        });
        vm.finance.refundableAccommodationDepositPaid = totaldap;
      }
    };

    vm.setRACPaid = function () {
      if ($rootScope.isBillingIntegratedFacility) return;
      //Todo: _filter and _foreach can be replaced with _.Chain
      var racEffectiveDates = _.filter(vm.finance.effectiveDates, (e) => e.effectiveDateType == "RacPaid");
      var totaldac = 0.0;
      if (racEffectiveDates.length > 0) {
        _.each(racEffectiveDates, function (item) {
          totaldac = totaldac + parseFloat(item.value);
        });
        vm.finance.refundableAccommodationContributionPaid = totaldac;
      }
    };

    vm.setContactEmailAddress = function (payerType, contact) {
      switch (payerType) {
        case "Resident": {
          vm.resident.email = contact.email;
          vm.resident.street1 = contact.street1;
          vm.resident.street2 = contact.street2;
          vm.resident.suburb = contact.suburb;
          vm.resident.stateDescription = contact.stateDescription;
          vm.resident.state = contact.state;
          vm.resident.postCode = contact.postCode;
          vm.resident.countryId = contact.countryId;
          break;
        }
        case "PrimaryContact": {
          vm.resident.primaryContact = contact;
          break;
        }
        case "SecondaryContact": {
          vm.resident.secondaryContact = contact;
          break;
        }
      }

      var billPayer = _.find(vm.billPayers, { payerType: payerType });
      billPayer.contact = contact;
      billPayer.exists = true;
      vm.prepareContactDataBeforeUpdate();
    };

    function initializeFinanceInformation() {
      vm.isResidentCareTypeRespite = vm.finance.residentCareType === "Respite";
      vm.isResidentCareTypePermanent = vm.finance.residentCareType === "Permanent";
      vm.existingBillPayer = vm.selectedBillPayer;
      if (!vm.finance.pre2014CareLevel) {
        vm.finance.pre2014CareLevel = "Bond";
      }

      vm.isUnknown = function () {
        return vm.finance.latestSupportLevel.value === "Unknown";
      };
      vm.isSupported = function () {
        return vm.finance.latestSupportLevel.value === "Supported";
      };
      vm.isUnsupported = function () {
        return vm.finance.latestSupportLevel.value === "Unsupported";
      };
      vm.displayExtraService = function () {
        return !vm.disableSendingExtraServiceToMedicare && vm.finance && (vm.isResidentCareTypePermanent || vm.isResidentCareTypeRespite) && !vm.isPrivateResidency;
      };

      vm.setRACPaid();
      vm.setRADPaid();

      initializeEffectiveValuesPopoverOptions();
    }

    vm.addOptionalService = function addOptionalService(form) {
      var serviceWithValidationErrors = _.find(vm.optionalServices, function (service) {
        if (!service.optionalServiceId || !service.startDateUtc) {
          return true;
        }
      });

      if (serviceWithValidationErrors) {
        for (var i = 0; i < vm.optionalServices.length; i++) {
          if (form.hasOwnProperty("serviceOptionalServiceId" + i)) form["serviceOptionalServiceId" + i].$setTouched();
          if (form.hasOwnProperty("serviceStartDate" + i)) form["serviceStartDate" + i].$setTouched();
          if (form.hasOwnProperty("serviceEndDate" + i)) form["serviceEndDate" + i].$setTouched();
        }
        return;
      }

      if (validateOptionalServiceOverlap(form)) {
        return;
      }

      vm.optionalServices.push({
        index: vm.optionalServices.length,
        optionalServiceId: null,
        startDateUtc: null,
        endDateUtc: null,
        stopped: false,
        new: true,
      });

      initializeReadonlyEffectiveDatePopoverConfig(vm.optionalServices.length - 1);
    };

    vm.validateOptionalService = function (form, service, index) {
      var valid = true;
      var startDateCtrl = form["serviceStartDate" + index];
      var endDateCtrl = form["serviceEndDate" + index];

      if (moment.utc(service.startDateUtc).isBefore(moment.utc(service.facilityOptionalServiceStartDateUtc))) {
        startDateCtrl.$setValidity("startDateBeforeServiceStartDate", false);
        valid = false;
      } else {
        startDateCtrl.$setValidity("startDateBeforeServiceStartDate", true);
      }

      if (service.facilityOptionalServiceEndDateUtc && moment.utc(service.startDateUtc).isAfter(moment.utc(service.facilityOptionalServiceEndDateUtc))) {
        startDateCtrl.$setValidity("startDateAfterServiceEndDate", false);
        valid = false;
      } else {
        startDateCtrl.$setValidity("startDateAfterServiceEndDate", true);
      }

      if (service.endDateUtc && moment.utc(service.endDateUtc).isBefore(service.startDateUtc)) {
        endDateCtrl.$setValidity("invalidDate", false);
        valid = false;
      } else {
        endDateCtrl.$setValidity("invalidDate", true);
      }

      if (service.endDateUtc && service.facilityOptionalServiceEndDateUtc && moment.utc(service.endDateUtc).isAfter(moment.utc(service.facilityOptionalServiceEndDateUtc))) {
        endDateCtrl.$setValidity("endDateAfterServiceEndDate", false);
        valid = false;
      } else {
        endDateCtrl.$setValidity("endDateAfterServiceEndDate", true);
      }

      if (validateOptionalServiceOverlap(form)) {
        valid = false;
      }

      return valid;
    };

    function lookupOptionalServiceFormRowIndex(form, optionalServiceIndex) {
      return Object.keys(form)
        .filter((key) => {
          return key.indexOf("index.") == 0 && form[key].$modelValue == optionalServiceIndex;
        })[0]
        .match(/\d+/)[0];
    }

    function validateOptionalServiceOverlap(form) {
      var hasError = false;

      for (var i = 0; i < vm.optionalServices.length; i++) {
        if (form.hasOwnProperty("serviceEndDate" + i)) form["serviceEndDate" + i].$setValidity("overlapDate", true);
      }

      // find the maximum date used, it will be used as the range delimiter for open date ranges (ie no end date)
      var maxDate = new Date();
      vm.optionalServices.forEach((optionalService) => {
        if (optionalService.startDateUtc > maxDate) maxDate = new Date(optionalService.startDateUtc.getTime());
        if (optionalService.endDateUtc > maxDate) maxDate = new Date(optionalService.endDateUtc.getTime());
      });

      // add a month onto the max date to give some time for the overlap to apply
      maxDate.setUTCMonth(maxDate.getUTCMonth() + 1);

      for (var i = 0; i < vm.optionalServices.length; i++) {
        if (!vm.optionalServices[i].startDateUtc) {
          continue;
        }

        // if no end date, use the max date
        var range1 = moment.range(vm.optionalServices[i].startDateUtc, vm.optionalServices[i].endDateUtc ? vm.optionalServices[i].endDateUtc : maxDate);

        for (var j = i + 1; j < vm.optionalServices.length; j++) {
          // only check overlaps for matching ids as different optional services can overlap
          if (vm.optionalServices[i].optionalServiceId != vm.optionalServices[j].optionalServiceId) continue;

          if (!vm.optionalServices[j].startDateUtc) {
            continue;
          }

          // don't check if both are stopped
          if (vm.optionalServices[i].stopped && vm.optionalServices[j].stopped) continue;

          // if no end date, use the max date
          var range2 = moment.range(vm.optionalServices[j].startDateUtc, vm.optionalServices[j].endDateUtc ? vm.optionalServices[j].endDateUtc : maxDate);

          if (range1.overlaps(range2, { adjacent: true })) {
            var formIndexI = lookupOptionalServiceFormRowIndex(form, i);
            var formIndexJ = lookupOptionalServiceFormRowIndex(form, j);

            if (form.hasOwnProperty("serviceEndDate" + formIndexI)) form["serviceEndDate" + formIndexI].$setValidity("overlapDate", false);

            if (form.hasOwnProperty("serviceEndDate" + formIndexJ)) form["serviceEndDate" + formIndexJ].$setValidity("overlapDate", false);
            hasError = true;
          }
        }
      }

      return hasError;
    }

    vm.validateHardship = function (form, hardship, index) {
      var endDateCtrl = form["hardshipEndDate" + index];
      var startDateCtrl = form["hardshipStartDate" + index];

      if (moment.utc(hardship.endDateUtc).isBefore(hardship.startDateUtc)) {
        endDateCtrl.$setValidity("invalidDate", false);
        return false;
      }

      if (moment.utc(hardship.startDateUtc).isBefore(vm.resident.admissionDateUtc)) {
        startDateCtrl.$setValidity("invalidBeforeAdmissionDate", false);
        return false;
      }

      validateHardshipsOverlap(form);

      endDateCtrl.$setValidity("invalidDate", true);
      startDateCtrl.$setValidity("invalidBeforeAdmissionDate", true);
      return false;
    };

    function validateHardshipsOverlap(form) {
      var hasError = false;

      for (var i = 0; i < vm.hardships.length; i++) {
        form["hardshipEndDate" + i].$setValidity("overlapDate", true);
      }

      for (var i = 0; i < vm.hardships.length; i++) {
        if (!vm.hardships[i].startDateUtc || !vm.hardships[i].endDateUtc) {
          continue;
        }
        var range1 = moment.range(vm.hardships[i].startDateUtc, vm.hardships[i].endDateUtc);

        for (var j = i + 1; j < vm.hardships.length; j++) {
          if (!vm.hardships[j].startDateUtc || !vm.hardships[j].endDateUtc) {
            continue;
          }

          var range2 = moment.range(vm.hardships[j].startDateUtc, vm.hardships[j].endDateUtc);

          if (range1.overlaps(range2, { adjacent: true })) {
            form["hardshipEndDate" + i].$setValidity("overlapDate", false);
            form["hardshipEndDate" + j].$setValidity("overlapDate", false);
            hasError = true;
          }
        }
      }

      return hasError;
    }

    vm.addHardship = function (form) {
      var hardshipWithValidationErrors = _.find(vm.hardships, function (hardship) {
        if (!hardship.endDateUtc || !hardship.startDateUtc || !hardship.governmentDailyContribution) {
          return true;
        }
      });

      if (hardshipWithValidationErrors) {
        var index = vm.hardships.length - 1;
        form["hardshipStartDate" + index].$setTouched();
        form["hardshipEndDate" + index].$setTouched();
        form["hardshipDailyContribution" + index].$setTouched();
        return;
      }

      if (validateHardshipsOverlap(form)) {
        return;
      }

      vm.hardships.push({
        startDateUtc: null,
        endDateUtc: null,
        governmentDailyContribution: null,
      });
    };

    vm.removeHardship = function (form, index) {
      if (vm.hardships.length === 0) {
        return;
      }

      dialogService.openActionDialog(
        "Delete hardship entry?",
        "This will reverse any payments made for this entry",
        "hardship-delete",
        "Delete",
        () => {
          vm.hardships.splice(index, 1);
        },
        "btn-danger btn-inline-block-xsm"
      );
    };

    vm.onEffectiveDateCellCreated = function (rowIndex, formattedValue) {
      if (rowIndex > 0 || vm.resident.isAdmitted) {
        return formattedValue;
      }

      return "Admission date";
    };

    vm.onDapAmountCellCreated = function (rowIndex, formattedValue) {
      if (rowIndex === 0 && !vm.resident.isAdmitted) {
        return $filter("currency")(vm.finance.dailyAccommodationPayment, "$", 2);
      }
      return formattedValue;
    };

    function initializeEffectiveValuesPopoverOptions(id, target) {
      const amountColumn = {
        headerText: "AMOUNT",
        headerCssClass: "amount-col",
        dataFieldName: "value",
        cellCssClass: "amount-col",
        filterName: "currency",
        currencySymbol: "$",
        currencyDecimalPlace: 2,
      };

      const dapAmountColumn = {
        headerText: "AMOUNT",
        headerCssClass: "amount-col",
        dataFieldName: "value",
        cellCssClass: "amount-col",
        filterName: "currency",
        currencySymbol: "$",
        currencyDecimalPlace: 2,
        onCellCreated: vm.onDapAmountCellCreated,
      };

      const effectiveDateColumn = {
        headerText: "EFFECTIVE DATE",
        headerCssClass: "effective-date-col",
        dataFieldName: "effectiveFromDate",
        cellCssClass: "effective-date-col",
        filterName: "dateInUtcTimezone",
        format: "dd MMM yyyy",
        onCellCreated: vm.onEffectiveDateCellCreated,
      };

      const supportLevelColumn = {
        headerText: "SUPPORT LEVEL",
        headerCssClass: "support-col",
        dataFieldName: "value",
        cellCssClass: "support-col",
      };

      const optionsTemplate = {
        placement: "bottom",
        autoClose: true,
        popoverCssClass: "fee-effective-popover",
        columns: [amountColumn, effectiveDateColumn],
        getRowCssClass: vm.getRowCssClass,
      };

      vm.meansTestedCareFeeOptions = _.extend({}, optionsTemplate, {
        id: "meanstestecarefee-link",
        target: "span#meanstestecarefee-effective-date",
      });

      vm.pre2014IncomeTestFeeOptions = _.extend({}, optionsTemplate, {
        id: "incometestedfee-link",
        target: "span#incometestedfee-effective-date",
      });

      vm.dailyAccommodationContributionOptions = _.extend({}, optionsTemplate, {
        id: "dac-link",
        target: "span#dac-effective-date",
      });

      vm.agreedRoomPriceOptions = _.extend({}, optionsTemplate, {
        id: "agreedroomprice-link",
        target: "span#agreedroomprice-effective-date",
      });

      vm.dapOptions = _.extend({}, optionsTemplate, {
        id: "dap-link",
        target: "span#dap-effective-date",
        columns: [dapAmountColumn, _.extend({}, effectiveDateColumn, { headerText: "PAYMENT DATE" })],
      });

      vm.topupDACOptions = _.extend({}, optionsTemplate, {
        id: "topupdac-link",
        target: "span#topupdac-effective-date",
        columns: [amountColumn, _.extend({}, effectiveDateColumn, { headerText: "PAYMENT DATE" })],
      });

      vm.radPaidOptions = _.extend({}, optionsTemplate, {
        id: "radpaid-link",
        target: "span#rad-effective-date",
        columns: [amountColumn, _.extend({}, effectiveDateColumn, { headerText: "PAYMENT DATE" })],
      });

      vm.racPaidOptions = _.extend({}, optionsTemplate, {
        id: "racpaid-link",
        target: "span#rac-effective-date",
        columns: [amountColumn, _.extend({}, effectiveDateColumn, { headerText: "PAYMENT DATE" })],
      });

      vm.supportLevelOptions = _.extend({}, optionsTemplate, {
        id: "supportlevel-link",
        target: "span#supportlevel-effective-date",
        columns: [supportLevelColumn, effectiveDateColumn],
      });

      vm.basicDailyCareFeeOptions = _.extend({}, optionsTemplate, {
        id: "basicDailyCareFee-link",
        target: "span#basicDailyCareFee-effective-date",
        columns: [amountColumn, _.extend({}, effectiveDateColumn, { headerText: "EFFECTIVE DATE" })],
      });

      vm.privateDailyCareFeeOptions = _.extend({}, optionsTemplate, {
        id: "privateDailyCareFee-link",
        target: "span#privateDailyCareFee-effective-date",
      });

      sortEffectiveInformation("MeansTestCareFee", "meansTestedCareFees");
      sortEffectiveInformation("Pre2014IncomeTestFee", "pre2014IncomeTestFees");
      sortEffectiveInformation("DailyAccommodationContribution", "dailyAccommodationContributions");
      sortEffectiveInformation("SupportLevel", "supportLevels");
      sortEffectiveInformation("AgreedRoomPrice", "agreedRoomPrices");
      sortEffectiveInformation("DailyAccommodationPayment", "dailyAccommodationPayments");
      sortEffectiveInformation("TopupDailyAccommodationContribution", "topupDailyAccommodationContributions");
      sortEffectiveInformation("RadPaid", "radPayments");
      sortEffectiveInformation("RacPaid", "racPayments");
      sortEffectiveInformation("BasicDailyCareFee", "basicDailyCareFees");
      sortEffectiveInformation("PrivateDailyCareFee", "privateDailyCareFees");
    }

    function sortEffectiveInformation(type, items) {
      var currentUtcNow = moment().utc();
      vm[items] = _.sortBy(_.where(vm.finance.effectiveDates, { effectiveDateType: type }), function (item) {
        return -moment(item.effectiveFromDate);
      });
    }

    vm.getRowCssClass = function (data) {
      return _.isEqual(data, vm.finance.latestMeansTestedCareFee) ||
        _.isEqual(data, vm.finance.latestPrivateDailyCareFee) ||
        _.isEqual(data, vm.finance.latestPre2014IncomeTestFee) ||
        _.isEqual(data, vm.finance.latestDailyAccommodationContribution) ||
        _.isEqual(data, vm.finance.latestSupportLevel) ||
        _.isEqual(data, vm.finance.latestDAP) ||
        _.isEqual(data, vm.finance.latestTopupDAC) ||
        _.isEqual(data, vm.finance.latestAgreedRoomPrice)
        ? "latest"
        : "";
    };

    function getBillPayer(contact) {
      var result = {
        name: "Add",
        contact: null,
        exists: false,
      };

      if (!contact) {
        return result;
      }

      if (!contact.firstName && !contact.lastName) {
        result.name = "Add";
        result.exists = false;
      } else {
        result.name = contact.firstName + " " + contact.lastName;
        result.exists = true;
      }

      result.contact = contact;
      return result;
    }

    function getResidentContact() {
      var contact = {};
      contact.email = vm.resident.email;

      contact.street1 = vm.resident.street1;
      contact.street2 = vm.resident.street2;
      contact.suburb = vm.resident.suburb;
      contact.stateDescription = vm.resident.stateDescription;
      contact.postCode = vm.resident.postCode;
      contact.country = vm.resident.country;

      return contact;
    }

    function initializeResidentInformation() {
      var primaryContact = getBillPayer(vm.resident.primaryContact);
      var secondaryContact = getBillPayer(vm.resident.secondaryContact);

      vm.billPayers = [
        {
          payerType: "empty",
          name: null,
          exists: true,
        },
        {
          payerType: "Resident",
          payerTypeName: "Resident",
          name: vm.resident.firstName + " " + vm.resident.lastName,
          billPayerDetail: vm.resident.billPayerDetail,
          contact: getResidentContact(),
          exists: true,
        },
        {
          payerType: "PrimaryContact",
          payerTypeName: "Primary contact",
          name: primaryContact.name,
          billPayerDetail: primaryContact.contact ? primaryContact.contact.billPayerDetail : null,
          contact: primaryContact.contact,
          exists: primaryContact.exists,
        },
        {
          payerType: "SecondaryContact",
          payerTypeName: "Secondary contact",
          name: secondaryContact.name,
          billPayerDetail: secondaryContact.contact ? secondaryContact.contact.billPayerDetail : null,
          contact: secondaryContact.contact,
          exists: secondaryContact.exists,
        },
      ];

      if (vm.finance.billPayerType) {
        vm.selectedBillPayer = _.find(vm.billPayers, { payerType: vm.finance.billPayerType });
        vm.existingBillPayer = vm.selectedBillPayer;
        setInvoiceMethodSelectedFlag();
        vm.populateCcEmailAddresses();
      }
    }

    function resetIsInvoiceEmailIfNoEmail() {
      if (vm.selectedBillPayer && vm.selectedBillPayer.billPayerDetail) {
        if (vm.selectedBillPayer.billPayerDetail.isInvoiceEmail && vm.selectedBillPayer.contact && vm.selectedBillPayer.contact.email === "") {
          vm.selectedBillPayer.billPayerDetail.isInvoiceEmail = false;
          vm.finance.isInvoiceEmail = false;
        }
      }
    }

    function resetIsInvoicePostIfNoPostalAddress() {
      if (vm.selectedBillPayer && vm.selectedBillPayer.billPayerDetail) {
        if (vm.selectedBillPayer.billPayerDetail.isInvoicePost && vm.selectedBillPayer.contact && !vm.hasContactPost()) {
          vm.selectedBillPayer.billPayerDetail.isInvoicePost = false;
          vm.finance.isInvoicePost = false;
        }
      }
    }

    function setInvoiceMethodSelectedFlag() {
      if (vm.selectedBillPayer && vm.selectedBillPayer.billPayerDetail) {
        if (!vm.selectedBillPayer.billPayerDetail.isInvoiceEmail && !vm.selectedBillPayer.billPayerDetail.isInvoicePost) {
          vm.billInvoiceMethod = null;
        } else {
          vm.billInvoiceMethod = true;
        }
      } else {
        vm.billInvoiceMethod = null;
      }
    }

    function initializeOptionalServiceInformation() {
      if (vm.facilityOptionalServices == null || vm.facilityOptionalServices.length == 0 || vm.optionalServices == null) return;

      var currentTime = new Date();

      // start with initialising the facility-level optional services

      // create a lookup array so we can easily refernce the service by its id
      vm.facilityOptionalServicesLookupById = [];

      vm.facilityOptionalServices.forEach((facilityOptionalService) => {
        // populate service dropdown options
        if (!facilityOptionalService.stopped) {
          vm.facilityServicesDropDownOptions.push({
            id: facilityOptionalService.id,
            text: facilityOptionalService.typeDescription + ` - ${facilityOptionalService.billingPeriod.toLowerCase()} until ${facilityOptionalService.stopBillingEvent.toLowerCase()}`,
          });
        }

        // populate the lookup
        vm.facilityOptionalServicesLookupById[parseInt(facilityOptionalService.id)] = facilityOptionalService;

        // convert the dates to Date objects
        facilityOptionalService.ratesList.forEach((rate) => {
          rate.startDate = new Date(rate.startDate.toString());
          rate.endDate = rate.endDate ? new Date(rate.endDate.toString()) : null;
        });

        // determine today's rate
        var useRate = facilityOptionalService.ratesList[facilityOptionalService.ratesList.length - 1];
        facilityOptionalService.ratesList
          .slice()
          .reverse()
          .forEach((rate) => {
            if (currentTime >= rate.startDate.getTime()) {
              useRate = rate;
            }
          });
        facilityOptionalService.rate = useRate.value;
        facilityOptionalService.todayRateIndex = facilityOptionalService.ratesList.indexOf(useRate);
      });

      // now do the resident-level optional services
      var index = 0;
      vm.optionalServices.forEach((optionalService) => {
        optionalService.index = index;
        index++;
        // convert the dates to Date objects
        optionalService.startDateUtc = new Date(optionalService.startDateUtc.toString());
        optionalService.endDateUtc = optionalService.endDateUtc ? new Date(optionalService.endDateUtc.replace("23:59:59", "00:00:00")) : null;

        let endDateUtcEndOfDay = null;
        if (optionalService.endDateUtc != null) {
          optionalService.endDateUtc = new Date(
            Date.UTC(
              optionalService.endDateUtc.getUTCFullYear(),
              optionalService.endDateUtc.getUTCMonth(),
              optionalService.endDateUtc.getUTCDate(),
              optionalService.endDateUtc.getUTCHours(),
              optionalService.endDateUtc.getUTCMinutes(),
              optionalService.endDateUtc.getUTCSeconds()
            )
          );
          endDateUtcEndOfDay = new Date(optionalService.endDateUtc);
          endDateUtcEndOfDay.setHours(23);
          endDateUtcEndOfDay.setMinutes(59);
          endDateUtcEndOfDay.setSeconds(59);
        }

        // use the facility rate, unless we're outside our service date range, then use 0
        optionalService.rate = vm.facilityOptionalServicesLookupById[optionalService.optionalServiceId].rate;
        if (currentTime.getTime() < optionalService.startDateUtc.getTime() || (endDateUtcEndOfDay != null && currentTime.getTime() > endDateUtcEndOfDay)) {
          optionalService.rate = "0";
        }

        optionalService.applicableRatesList = prepareRatesListForResidentServiceWindow(
          vm.facilityOptionalServicesLookupById[optionalService.optionalServiceId].ratesList,
          vm.resident.admissionDateUtc,
          optionalService.startDateUtc,
          optionalService.endDateUtc
        );
      });
    }

    function prepareRatesListForResidentServiceWindow(ratesList, admissionDate, serviceStartDate, serviceEndDate) {
      admissionDate = new Date(admissionDate);
      var output = ratesList.slice();
      var windowStart = admissionDate != null && admissionDate.getTime() > serviceStartDate.getTime() ? admissionDate : serviceStartDate;
      var windowEnd = serviceEndDate ? new Date(serviceEndDate) : null;

      // delete all entries that are completely outside our window
      for (let i = 0; i < output.length; i++) {
        var startDate = output[i].startDate;
        var endDate = output[i].endDate;
        var value = output[i].value;
        var deleteThisEntry = false;

        if (endDate != null && endDate.getTime() < windowStart.getTime()) {
          deleteThisEntry = true;
        } else if (windowEnd != null && windowEnd.getTime() < startDate.getTime()) {
          deleteThisEntry = true;
        }
        if (deleteThisEntry) {
          output.splice(i--, 1);
        } else {
          output[i] = {
            startDate: startDate,
            endDate: endDate,
            value: value,
          };
        }
      }

      // if we have no entries remaining (ie there are no rates within our window) then just return zero from the window start
      if (output.length == 0) {
        output.push({
          startDate: windowStart,
          value: "0",
          endDate: null,
        });
        return output;
      }

      // set the start date of the earliest rate to match our window start if necessary
      var earliestRate = output[output.length - 1];
      if (earliestRate.startDate.getTime() < windowStart.getTime()) {
        earliestRate.startDate = windowStart;
      }
      // set the end date of the latest rate to match our window end if necessary
      var latestRate = output[0];
      if ((latestRate.endDate != null && windowEnd != null && latestRate.endDate.getTime() > windowEnd.getTime()) || latestRate.endDate == null) {
        latestRate.endDate = windowEnd;
      }
      // if the latest rate isn't a zero, but it has an end date, then add a new zero to signify the end of the service
      if (latestRate.value != "0" && latestRate.endDate != null) {
        var zeroEffectiveDate = new Date(latestRate.endDate.getTime());

        zeroEffectiveDate.setDate(zeroEffectiveDate.getDate() + 1);

        output.unshift({
          startDate: zeroEffectiveDate,
          value: "0",
        });
      }

      return output;
    }

    function initializeReadonlyEffectiveDatePopoverConfig(index = null) {
      if (vm.facilityOptionalServices == null || vm.facilityOptionalServices.length === 0 || vm.optionalServices == null) return;

      if (index === null) {
        vm.readonlyEffectiveDatePopoverConfigs = [];
        vm.optionalServices.forEach((service) => {
          initializeReadonlyEffectiveDatePopoverConfig(service.index);
        });
      } else {
        vm.readonlyEffectiveDatePopoverConfigs[index] = {
          id: "value-link-" + index,
          placement: "bottom",
          target: "a#value-link-" + index,
          autoClose: true,
          popoverCssClass: "fee-effective-popover",
          columns: [
            {
              headerText: "AMOUNT",
              headerCssClass: "support-col",
              dataFieldName: "value",
              cellCssClass: "support-col",
              filterName: "currency",
            },
            {
              headerText: "EFFECTIVE DATE",
              headerCssClass: "effective-date-col",
              dataFieldName: "startDate",
              cellCssClass: "effective-date-col",
              filterName: "date",
              format: "dd MMM yyyy",
              onCellCreated: (rowIndex, formattedValue) => {
                return formattedValue;
              },
            },
          ],
        };
      }
    }

    vm.removeOptionalService = function (optionalService) {
      vm.optionalServices.splice(vm.optionalServices.indexOf(optionalService), 1);
    };

    var promises = {
      finance: residentsService.getResidentFinanceFees(residentId),
      resident:
        $state.current.name === "editResident.financialsFees" || $state.current.name === "viewResident.financialsFees" ? residentsService.getResident(residentId) : leadsService.getLead(residentId),
      facilityOptionalServices: organisationFacilityService.getOptionalServices(),
    };

    $q.all(promises).then(
      function (results) {
        vm.finance = results.finance.financeFees;
        vm.setBasicDailyFeeToZero = vm.finance.isBasicDailyFeeZeroForPrivateResident;
        vm.facility = results.finance.facility;
        vm.resident = results.resident;
        vm.optionalServices = results.finance.financeFees.optionalServices;
        vm.hardships = results.finance.financeFees.hardships;
        vm.facilityOptionalServices = results.facilityOptionalServices;
        initializeFinanceInformation();
        initializeResidentInformation();
        initializeOptionalServiceInformation();
        initializeReadonlyEffectiveDatePopoverConfig();

        referenceDataService.getCountries().then(
          (data) => {
            vm.countries = data;
            const emptyItem = {
              id: null,
              displayName: "",
            };

            vm.countries.splice(0, 0, emptyItem);
            vm.prepareContactDataBeforeUpdate();

            vm.selectedCountry = _.findWhere(vm.countries, { id: vm.resident.countryId }).displayName;
          },
          () => {
            notification.error("Unexpected error occurred while loading the countries.");
          }
        );

        $timeout(function () {
          if ($stateParams["#"]) {
            $location.hash($stateParams["#"]);
            $anchorScroll();
          }
        }, 0);
      },
      function (error) {
        if (error.status === 404) {
          vm.finance = {
            financeFees: {
              effectiveDates: [],
            },
          };
        } else {
          notification.error("Unable to display financials. Either your computer is offline, or Resident Manager is having problems of its own.");
        }
      }
    );

    vm.contactEmail = function () {
      return vm.selectedBillPayer ? (vm.selectedBillPayer.contact ? vm.selectedBillPayer.contact.email : "No email") : "No email";
    };

    vm.hasContact = function () {
      if (vm.selectedBillPayer) {
        if (vm.selectedBillPayer.contact) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    };

    vm.hasContactEmail = function () {
      if (vm.selectedBillPayer) {
        if (vm.selectedBillPayer.contact) {
          return vm.selectedBillPayer.contact.email;
        } else {
          return false;
        }
      }

      return false;
    };

    vm.hasContactPost = function () {
      if (vm.selectedBillPayer) {
        if (vm.selectedBillPayer.contact) {
          return (
            vm.selectedBillPayer.contact.street1 ||
            vm.selectedBillPayer.contact.street2 ||
            vm.selectedBillPayer.contact.suburb ||
            vm.selectedBillPayer.contact.stateDescription ||
            vm.selectedBillPayer.contact.postCode ||
            vm.selectedBillPayer.contact.country
          );
        } else {
          return false;
        }
      }

      return false;
    };

    vm.invoiceEmailChecked = function (checked) {
      if (vm.selectedBillPayer && vm.selectedBillPayer.billPayerDetail) {
        vm.selectedBillPayer.billPayerDetail.isInvoiceEmail = checked;
        vm.isCheckboxTouched = true;
      } else {
        vm.selectedBillPayer.billPayerDetail = vm.getNewBillPayerDetail();
        vm.selectedBillPayer.billPayerDetail.isInvoiceEmail = checked;
        vm.isCheckboxTouched = false;
      }
      if (!checked && !vm.finance.isInvoicePost) {
        vm.billInvoiceMethod = null;
      } else {
        vm.finance.isInvoiceEmail = checked;
        vm.billInvoiceMethod = checked || vm.finance.isInvoicePost;
      }
    };

    vm.invoicePostChecked = function (checked) {
      if (vm.selectedBillPayer && vm.selectedBillPayer.billPayerDetail) {
        vm.selectedBillPayer.billPayerDetail.isInvoicePost = checked;
        vm.isCheckboxTouched = true;
      } else {
        vm.selectedBillPayer.billPayerDetail = vm.getNewBillPayerDetail();
        vm.selectedBillPayer.billPayerDetail.isInvoicePost = checked;
        vm.isCheckboxTouched = false;
      }
      if (!vm.finance.isInvoiceEmail && !checked) {
        vm.billInvoiceMethod = null;
      } else {
        vm.finance.isInvoicePost = checked;
        vm.billInvoiceMethod = checked || vm.finance.isInvoiceEmail;
      }
    };

    vm.billPaymentMethodName = function () {
      return vm.finance.billPaymentMethod === "DirectDebit" ? "Direct debit" : vm.finance.billPaymentMethod;
    };

    vm.isAnyDrawdownSelected = function () {
      if (vm.finance.drawdownDAC || vm.finance.drawdownBDF || vm.finance.drawdownMTCF || vm.finance.drawdownAC || vm.finance.drawdownPI || vm.finance.drawdownITF) {
        return true;
      }

      return false;
    };

    vm.redirectToViewDetail = function () {
      var goToState = $state.current.name === "viewResident.financialsFees" ? "viewResident" : "viewLead";
      $state.go(goToState, { residentId: $scope.residentId, "#": vm.selectedBillPayer.payerType.substring(0, 1).toLowerCase() + vm.selectedBillPayer.payerType.substring(1) });
    };

    vm.save = function (isvalid) {
      if (vm.selectedBillPayer?.billPayerDetail?.isInvoicePost && !vm.hasContactPost()) {
        isvalid = false;
      }
      if (!isvalid) {
        return;
      }
      let ccEmails = "";
      if (vm.selectedBillPayer?.billPayerDetail?.isInvoiceEmail) {
        ccEmails = vm.ccEmails.replaceAll(",", vm.ccEmailDelimiter);

        if (vm.contact.billPayerDetail) {
          vm.contact.billPayerDetail.ccEmail = ccEmails;
        } else {
          vm.contact.billPayerDetail = vm.getNewBillPayerDetail();
          vm.contact.billPayerDetail.isInvoiceEmail = true;
          vm.contact.billPayerDetail.ccEmail = ccEmails;
        }
        vm.saveContact(isvalid);
      }
      if (vm.selectedBillPayer) {
        if (vm.selectedBillPayer.payerType !== "empty") {
          vm.finance.billPayerType = vm.selectedBillPayer.payerType;
          vm.finance.billPaymentMethod = vm.selectedBillPayer.billPayerDetail.paymentMethod;
          vm.finance.isInvoiceEmail = vm.selectedBillPayer.billPayerDetail.isInvoiceEmail;
          vm.finance.isInvoicePost = vm.selectedBillPayer.billPayerDetail.isInvoicePost;
          vm.finance.ccEmail = ccEmails;
          if (vm.selectedBillPayer.billPayerDetail.paymentMethod === "DirectDebit") {
            vm.finance.bankAccountName = vm.selectedBillPayer.billPayerDetail.accountName;
            vm.finance.bankName = vm.selectedBillPayer.billPayerDetail.bankName;
            vm.finance.bsb = vm.selectedBillPayer.billPayerDetail.bsb;
            vm.finance.accountNumber = vm.selectedBillPayer.billPayerDetail.accountNumber;
          }
        } else {
          vm.finance.billPayerType = null;
        }
      }
      vm.finance.effectiveDates = _.filter(vm.finance.effectiveDates, function (effectiveDateItem) {
        return effectiveDateItem.effectiveDateType !== EffectiveDateTypes.BasicDailyCareFee;
      });

      vm.finance.isBasicDailyFeeZeroForPrivateResident = vm.setBasicDailyFeeToZero;

      residentsService.saveResidentFinanceFees(residentId, vm.finance).then(
        function () {
          notification.success("Changes have been saved.");
          vm.redirectToView();
        },
        function () {
          notification.error("Unable to save changes. Either your computer is offline, or Resident Manager is having problems of its own.");
        }
      );
    };

    vm.redirectToView = function () {
      if ($state.current.name === "editResident.financialsFees") {
        $state.go(stateViewResidentFinancialsFees, { residentId: residentId, isPrivateResidency: this.isPrivateResidency });
      } else if ($state.current.name === "editLead.financialsFees") {
        $state.go(stateViewLeadFinancialsFees, { residentId: residentId, isPrivateResidency: this.isPrivateResidency });
      }
    };

    vm.isAccommodationCostRequiredForPre2014Care = function () {
      return vm.finance.pre2014Care && vm.finance.pre2014ContinueCare && vm.finance.pre2014CareLevel === "Bond";
    };

    vm.showFundingTab = function () {
      switch ($state.current.name) {
        case "viewResident.financialsFees":
        case "editResident.financialsFees":
          $state.go("viewResident.financialsFunding", { residentId: residentId });
          break;
        case "viewLead.financialsFees":
        case "editLead.financialsFees":
          $state.go("viewLead.financialsFunding", { residentId: residentId });
          break;
      }
    };

    vm.showTransactionTab = function () {
      switch ($state.current.name) {
        case "viewResident.financialsFees":
        case "editResident.financialsFees":
          $state.go("viewResident.financialsTransaction", { residentId: residentId, isPrivateResidency: this.isPrivateResidency });
          break;
        case "viewLead.financialsFees":
        case "editLead.financialsFees":
          $state.go("viewLead.financialsTransaction", { residentId: residentId, isPrivateResidency: this.isPrivateResidency });
          break;
      }
    };

    vm.navigateToEdit = function () {
      switch ($state.current.name) {
        case "viewResident.financialsFees":
          $state.go("editResident.financialsFees", { residentId: residentId, isPrivateResidency: this.isPrivateResidency });
          break;
        case "viewLead.financialsFees":
          $state.go("editLead.financialsFees", { residentId: residentId, isPrivateResidency: this.isPrivateResidency });
          break;
      }
    };

    vm.showMeansTestedCareFeesChangeHistory = function () {
      dialogService.openDialog("app/residents/partials/meansTestedCareFeesChangeHistoryDialog.html", "meansTestedCareFeesChangeHistoryDialog.controller", {
        residentName: `${vm.resident.firstName} ${vm.resident.lastName}`,
        meansTestedCareFees: vm.meansTestedCareFees,
      });
    };

    vm.addNewCcEmailAddress = function (ccEmailId = "") {
      vm.emailAddresses.push({ name: "ccEmail" + vm.ccIndex, value: ccEmailId });
      vm.ccIndex++;

      vm.getCcEmailIds();
    };

    vm.saveContact = (isValid) => {
      if (isValid) {
        const selectedCountry = vm.countries.filter((c) => c.id == vm.contact.countryId);

        if (selectedCountry.length > 0) {
          vm.contact.country = selectedCountry[0].displayName;
        }

        let resident = vm.setResidentContactDataBeforeUpdate();
        let updateResident = $state.current.name === "editResident.financialsFees" ? residentsService.updateResident : leadsService.updateLead;
        updateResident({ resident: resident }).then(
          () => {
            vm.setContactEmailAddress(vm.selectedBillPayer.payerType, vm.contact);
          },
          () => {
            notification.error("Unexpected error occurred while saving " + this.modalConfig.payerTypeName + " " + this.modalConfig.contactField + ".");
          }
        );
      }
    };

    vm.populateCcEmailAddresses = () => {
      vm.resetCcEmails();
      let ccEmails = [];
      let ccEmail = "";
      switch (vm.selectedBillPayer.payerType) {
        case "Resident":
          ccEmail = vm.resident.billPayerDetail?.ccEmail;
          break;
        case "PrimaryContact":
          ccEmail = vm.resident.primaryContact?.billPayerDetail?.ccEmail;
          break;
        case "SecondaryContact":
          ccEmail = vm.resident.secondaryContact?.billPayerDetail?.ccEmail;
          break;
        default:
          break;
      }
      if (ccEmail) {
        ccEmails = ccEmail?.split(vm.ccEmailDelimiter);
        ccEmails?.forEach((ccEmail) => {
          vm.addNewCcEmailAddress(ccEmail);
        });
      }
    };

    vm.resetCcEmails = () => {
      vm.emailAddresses = [{ name: "email", value: "" }];
      vm.ccIndex = 0;
    };

    vm.getCcEmailIds = () => {
      vm.ccEmails = vm.emailAddresses
        .map(function (item) {
          return item.value;
        })
        .filter(Boolean)
        .toString();

      if (vm.ccEmails.length > 0) {
        vm.ccEmails = vm.ccEmails.charAt(vm.ccEmails.length - 1) == "," ? vm.ccEmails.slice(0, -1) : vm.ccEmails;
        vm.ccEmails = vm.ccEmails.charAt(0) == "," ? vm.ccEmails.slice(1) : vm.ccEmails;
      }

      // If CC Email length exceed to 150 characters then show validation message.
      if (vm.ccEmails.length > vm.ccEmailLengthLimit) {
        vm.invalidCCEmailLengthDisplayed = true;
      } else {
        vm.invalidCCEmailLengthDisplayed = false;
      }
    };

    vm.setResidentContactDataBeforeUpdate = () => {
      let resident = {};
      angular.extend(resident, vm.resident);
      resident.billPayerType = vm.selectedBillPayer.payerType;

      switch (vm.selectedBillPayer.payerType) {
        case "Resident": {
          resident.email = vm.contact.email;
          resident.street1 = vm.contact.street1;
          resident.street2 = vm.contact.street2;
          resident.suburb = vm.contact.suburb;
          resident.state = vm.contact.state;
          resident.postCode = vm.contact.postCode;
          resident.countryId = vm.contact.countryId;
          resident.country = vm.contact.country;
          if (resident.billPayerDetail) {
            resident.billPayerDetail.ccEmail = vm.contact?.billPayerDetail?.ccEmail;
          } else {
            resident.billPayerDetail = vm.getNewBillPayerDetail();
            resident.billPayerDetail.isInvoiceEmail = true;
            resident.billPayerDetail.ccEmail = vm.contact?.billPayerDetail?.ccEmail;
          }
          break;
        }
        case "PrimaryContact": {
          resident.primaryContact = vm.contact;
          resident.primaryContact.isBillPayer = true;
          if (resident.secondaryContact) {
            resident.secondaryContact.isBillPayer = false;
          }
          if (resident.primaryContact.billPayerDetail) {
            resident.primaryContact.billPayerDetail.ccEmail = vm.contact?.billPayerDetail?.ccEmail;
          } else {
            resident.primaryContact.billPayerDetail = vm.getNewBillPayerDetail();
            resident.billPayerDetail.isInvoiceEmail = true;
            resident.primaryContact.billPayerDetail.ccEmail = vm.contact?.billPayerDetail?.ccEmail;
          }

          break;
        }
        case "SecondaryContact": {
          resident.secondaryContact = vm.contact;
          resident.secondaryContact.isBillPayer = true;
          if (resident.primaryContact) {
            resident.primaryContact.isBillPayer = false;
          }
          if (resident.secondaryContact.billPayerDetail) {
            resident.secondaryContact.billPayerDetail.ccEmail = vm.contact?.billPayerDetail?.ccEmail;
          } else {
            resident.secondaryContact.billPayerDetail = vm.getNewBillPayerDetail();
            resident.billPayerDetail.isInvoiceEmail = true;
            resident.secondaryContact.billPayerDetail.ccEmail = vm.contact?.billPayerDetail?.ccEmail;
          }
          break;
        }
      }

      resident.stateDescription = utilitiesService.getStateDescription(vm.contact.state);

      return resident;
    };

    vm.getNewBillPayerDetail = function () {
      return {
        ccEmail: null,
        isInvoiceEmail: null,
        isInvoicePost: null,
      };
    };

    vm.deleteRow = function (index) {
      if (vm.emailAddresses.length === 0) {
        return;
      }
      dialogService.openActionDialog(
        "Delete email address",
        "Are you sure you want to delete this email address?",
        "hardship-delete",
        "Delete",
        () => {
          vm.emailAddresses.splice(index, 1);
          vm.getCcEmailIds();
        },
        "btn-danger btn-inline-block-xsm"
      );
    };

    vm.prepareContactDataBeforeUpdate = function () {
      switch (vm.selectedBillPayer?.payerType) {
        case "Resident": {
          vm.contact.email = vm.resident.email;
          vm.contact.street1 = vm.resident.street1;
          vm.contact.street2 = vm.resident.street2;
          vm.contact.suburb = vm.resident.suburb;
          vm.contact.stateDescription = utilitiesService.getStateDescription(vm.resident.state);
          vm.contact.state = vm.resident.state;
          vm.contact.postCode = vm.resident.postCode;
          vm.contact.countryId = vm.resident.countryId;
          break;
        }
        case "PrimaryContact": {
          angular.extend(vm.contact, vm.resident.primaryContact);
          break;
        }
        case "SecondaryContact": {
          angular.extend(vm.contact, vm.resident.secondaryContact);
          break;
        }
      }
    };

    vm.markFormValid = function (event) {
      if (event.isUnique) {
        vm.makeFormValid();
      }
    };

    vm.makeFormValid = function () {
      angular.forEach(vm.saveFinanceForm, function (field, key) {
        if (key[0] !== "$" && key.indexOf("ccEmail") > -1) {
          field.$setValidity("customError", true); // Clear error
          field.$setPristine();
          field.$setUntouched();
        }
      });
      vm.triggerUniqueValidation = true;

      // Optionally, reset after validation is triggered
      $timeout(function () {
        vm.triggerUniqueValidation = false; // Reset trigger to false for future uses
      }, 0);
    };
  },
];
