"use strict";
const Oidc = require("oidc-client");
const _ = require("underscore");

module.exports = [
	"notification.service",
	"appSettings",
	"$log",
	"$q",
	"$location",
	"$state",
	"$rootScope",
	"$http",
	function (notification, appSettings, $log, $q, $location, $state, $rootScope, $http) {
		Oidc.Log.logger = $log;
		Oidc.Log.level = Oidc.Log.INFO;

		/**
		 * The current user's permissions.
		 * 
		 * @description User permissions need to first be resolved from the API.
		 * There is a small window of time, while the app is being bootstrapped, 
		 * that the permission will be empty until they are resolved.
		 * 
		 * Perviously, an user's permissions was contained with the cookie, but 
		 * now that user permission are fetech from the API it is recommended 
		 * that any code checking for a user permission avoid referencing this 
		 * directly. Worse case, the permission will not be found. Instead, it 
		 * is recommended that @see userHasPermissionDeferred is used to check 
		 * permissions.
		 */
		var userPermissions = {};

		function addPortString(port) {
			if (port === 80 || port === 443) {
				return "";
			}
			return ":" + port;
		}

		var anonymousUrls = {
			login: '/login',
			logout: '/logout',
			forgotPassword: '/forgotPassword',
			resetPassword: '/resetPassword'
		};

		var loginUrl = $location.protocol() + "://" + $location.host() + addPortString($location.port()) + "/#" + anonymousUrls.login;
		var silentLoginUrl = $location.protocol() + "://" + $location.host() + addPortString($location.port()) + "/app/authentication/partials/silentLogin.html";
		var permissionsEndpoint = "/users/facilities-permissions";

		var settings = {
			userStore: new Oidc.WebStorageStateStore({ store: localStorage }),
			authority: appSettings.IdentityServerAuthority,
			client_id: "CM.Web",
			redirect_uri: loginUrl,
			post_logout_redirect_uri: loginUrl,
			silent_redirect_uri: silentLoginUrl,
			silentRequestTimeout: 55000, //The silent token request triggers one minute prior to the token expiring. The default 10seconds is not enough on a slow connecion, so this has been increased to give a greater chance for the token to be refreshed in the background
			response_type: "id_token token",
			scope: "iCareHealth.CareManagement.API openid profile email address resman",
			automaticSilentRenew: true,
			filterProtocolClaims: true,
			loadUserInfo: true
		};
		var userManager = new Oidc.UserManager(settings);
		var loggedInUser;

		var permissionCodes = {
			// if you add or modify any permission code please also remember to modify corresponding class in C#

			leadsDetails: 101,
			leadsFinancials: 102,
			leadsRemoveDocuments: 103,
			leadsDeleteLeads: 104,
			leadsMarkAsProspect: 105,
			leadsAdmit: 106,

			residentsDetails: 201,
			residentsFinancials: 202,
			residentsRemoveDocuments: 203,
			residentsDepart: 204,
			residentsSendAndReturnFromLeave: 205,
			residentsSubmitACFI: 206,
			residentsEnterACFIManually: 207,
			residentsMarkDepartedAsEnquiry: 208,
			residentsMakePrivateFunded: 209,
			residentsEnterExpectedAnacc: 210,
			residentsRecordReassessmentRequest: 211,

			fundingMedicareEvents: 301,
			fundingScheduleOfCareRates: 302,
			fundingScheduleOfAccommodationRates: 303,
			fundingReviewFundingStatements: 304,
			fundingFinaliseClaim: 305,
			fundingCorrectEvents: 306,
			fundingReconciliation: 307,
			fundingFinaliseAdjustment: 308,
			fundingReverseEvents: 309,

			facilityDetails: 401,
			facilityRooms: 402,
			facilityRoomTypes: 403,
			facilityAccountMapping: 405,
			facilityOptionalServices: 406,

			manageUsers: 501,
			manageRoles: 502,
			activateDeactivateUsers: 503,

			organisationDetails: 601,
			deviceActivation: 602,
			deviceExpiryNotification: 603,

			feesScheduleOfFeesAndCharges: 701,

			billingSundryCharges: 801,
			billingPostCharges: 802,

			reportingMovements: 901,
			reportingEnquirySource: 902,
			reportingAccommodations: 903,
			reportingSettlements: 904,
			reportingMtcfs: 905,
			reportingFinancialDetails: 906,
			reportingBillingExtract: 907,
			reportingClassificationHistory: 908,
			palliativeAssessments: 909,
			potentialReassessments: 910,
			reportingReassessmentRequests: 911,
			reportingCareMinutes: 912,
			reportingResidentsCardStatus: 913
		};

		function parseUserPermissions(permissionString) {
			var permissions = JSON.parse(permissionString || "[]");
			_.chain(permissionCodes)
				.keys()
				.each(function (key) {
					var permissionCode = permissionCodes[key];
					var permissionEntry = _.findWhere(permissions, { c: permissionCode }) || {};
					userPermissions[key] = {
						canView: permissionEntry.v === 1,
						canModify: permissionEntry.m === 1,
						canRemove: permissionEntry.r === 1,
						canAction: permissionEntry.a === 1
					};
				});
		}

		///////////////////////////////
		// methods
		///////////////////////////////

		function setUserLoadedState(value) {
			$rootScope.userLoaded = value;
			$rootScope.$applyAsync();
		}

		function setAuthenticationNotRequiredState(value) {
			$rootScope.authenticationNotRequired = value;
			$rootScope.$applyAsync();
		}

		function setSystemUserState(value) {
			$rootScope.isSystemUser = value;
			$rootScope.$applyAsync();
		}

		//promise is resolved to a user if logged in otherwise null
		function getUser() {
			var deferred = $q.defer();
			userManager
				.getUser()
				.then(function (user) {

					var config = {
						headers: {
							Authorization: null
						}
					};
					if (user && user.access_token) {
						config.headers.Authorization = user.token_type + " " + user.access_token;
					}

					if (user && !user.expired) {
						var getUserPermissions = new Promise((resolve, reject) => {
							// If we already have the permissions move on - reduce network calls
							if (user.profile.resman_permissions !== undefined) {
								resolve(user);
								$rootScope.$apply();
							} else {
								$http.get(appSettings.ApiUrl + permissionsEndpoint, config).then(function (result) {
									user.profile.resman_permissions = result.data.permissions;
									user.profile.resman_fac_ids = result.data.facilityInfoList;

									//Store the user with the additional permissions
									userManager.storeUser(user).then(function () {
										resolve(user);
										$rootScope.$apply();
									});
								}, function (err) {
									reject(err);
								});
							}
						});
						getUserPermissions.then(user => {
							parseUserPermissions(user.profile.resman_permissions);
							setSystemUserState(user.profile.is_system_user == 'true' ? true : false);
							setUserLoadedState(true);
							deferred.resolve(user);
							$rootScope.$apply();
						}).catch(function (err) {
							deferred.reject(err);
							$rootScope.$apply();
						});
					} else {
						setUserLoadedState(false);
						setSystemUserState(false);
						deferred.resolve(null);
						$rootScope.$applyAsync();
					}
				})
				.catch(function (err) {
					$log.error(err);
					deferred.reject(err);
					$rootScope.$applyAsync();
					setUserLoadedState(false);
					setSystemUserState(false);
				});
			return deferred.promise;
		}

		function signinRedirect() {
			return userManager
				.signinRedirect()
				.then(function () {
					$log.info("signinRedirect done");
				})
				.catch(function (error) {
					notification.error("Unable to sign in.");
					$log.error(error);
					return $q.reject(error);
				});
		}

		function signinRedirectCallback(oidResponse) {
			return userManager
				.signinRedirectCallback(oidResponse)
				.then(function (user) {
					return getUser()
						.then(function (user) {
							$log.info("signed in", user);
							return user;
						});
				})
				.catch(function (err) {
					$log.error(err);
					return $q.reject(err);
				});
		}

		function signoutRedirect() {
			var params = undefined;
			if (loggedInUser && loggedInUser.id_token) {
				params = {
					id_token_hint: loggedInUser.id_token
				};
			}
			setUserLoadedState(false);
			loggedInUser = null;
			return userManager
				.signoutRedirect(params)
				.then(function (resp) {
					$log.info("signed out", resp);
					return resp;
				})
				.catch(function (err) {
					$log.error(err);
					return $q.reject(err);
				});
		}

		function signoutRedirectCallback() {
			return userManager
				.signoutRedirectCallback()
				.then(function (resp) {
					$log.info("signed out", resp);
					return resp;
				})
				.catch(function (err) {
					$log.error(err);
					return $q.reject(err);
				});
		}

		function processSigninRedirectCallback() {
			var deferred = $q.defer();
			if ($location.path().indexOf("id_token") > -1) {
				signinRedirectCallback($location.path().replace(anonymousUrls.login, "")).then(
					function (user) {
						if (user && !user.expired) {
							$state.go("dashboard");
						} else {
							$log.error("Unexpected error: user is not defined in signinRedirectCallback");
						}
						deferred.resolve();
					},
					function (error) {
						$log.error(error);
						deferred.reject();
					}
				);
			} else {
				//if no id_token in url, it means its a redirection after logging out, we need to process signout callback and redirect to authorization server login page
				signoutRedirectCallback().then(signinRedirect, signinRedirect).then(() => {
					deferred.resolve();
				});
			}
			return deferred.promise;
		}

		function redirectToLoginIfNotLoggedIn() {
			var deferred = $q.defer();
			$log.info("authentication: starting authentication check");
			notification.startLoading();
			getUser().then(
				function (user) {
					$log.info("authentication: getUser resulted with user = " + user);
					notification.stopLoading();
					if (!user || user.expired) {
						if ($location.path().indexOf(anonymousUrls.login) === -1 && $location.path().indexOf(anonymousUrls.logout) === -1) {
							$log.info("authentication: about to redirect to login state");
							signinRedirect().then(() => {
								deferred.resolve();
							});
						} else {
							deferred.resolve();
						}
						$log.info("authentication: redirect to login state is skipped becuase current path is " + $location.path());
					} else {
						deferred.resolve();
					}
				},
				function (error) {
					$log.debug(error);
					notification.stopLoading();
					notification.error("Unable to authenticate sign in details.");
					signinRedirect().then(() => {
						deferred.resolve();
					});
				}
			);
			return deferred.promise;
		}

		function applyAuthentication() {
			var deferred = $q.defer();
			setUserLoadedState(false);
			if ($location.path().indexOf(anonymousUrls.resetPassword) > -1) {
				setAuthenticationNotRequiredState(true);
				deferred.resolve();
				$state.go("resetPassword");
			} else if ($location.path().indexOf(anonymousUrls.forgotPassword) > -1) {
				setAuthenticationNotRequiredState(true);
				deferred.resolve();
				$state.go("forgotPassword");
			} else if ($location.path().indexOf(anonymousUrls.login) > -1) {
				processSigninRedirectCallback().then(() => {
					deferred.resolve();
				});
			} else {
				redirectToLoginIfNotLoggedIn().then(() => {
					deferred.resolve();
				});
			}
			return deferred.promise;
		}

		/**
		 * A defered task to check a user's permissions that resolves when user permissions have been loaded
		 * 
		 * @param {*} permissionCheckFn A function that defines the user permission check. The reurn value 
		 * 								should be a truthy to indicate the user permission check was successful, 
		 * 								otherwise it should be falsy. The function is called with the following 
		 * 								arguments:
		 * 
		 * 								userPermissions
		 * 									The user's permissions object. Will never be undefined.
		 * @returns Returns a new instaced of @see deferred that will be resolve if the user permission check was sucessful
		 * 			AND the permissionCheckFn returns truthy. Otherwise the deferred task will be rejected. 
		 * 
		 * @description 
		 * 
		 * This method is used to check permissions for UI routing states. But now that user permissions source directly 
		 * from the API, instead of being contained in the cookie, it is recommend that this method should be used when 
		 * checking user permissions from code allowing the check to be defferred until the API call has been completed.
		 * 
		 * @example
		 * vm.canRecordReassessmentRequest = false;
		 * authenticationService.userHasPermissionDeferred((x) => {
				 *      return x.residentsRecordReassessmentRequest.canAction;
				 *  }).then(() => {
				 *		vm.canRecordReassessmentRequest = true;
				 *	}, () => {
				 *  	vm.canRecordReassessmentRequest = false;
				 *	});
		 */
		function userHasPermissionDeferred(permissionCheckFn) {
			var deferred = $q.defer();
			if ($rootScope.userLoaded) {
				if (permissionCheckFn(userPermissions)) {
					deferred.resolve();
				} else {
					deferred.reject();
				}
			} else {
				getUser()
					.then(function () {
						if (permissionCheckFn(userPermissions)) {
							deferred.resolve();
						} else {
							deferred.reject();
						}
					})
					.catch(function () {
						deferred.reject();
					});
			}
			return deferred.promise;
		}

		///////////////////////////////
		// events
		///////////////////////////////
		userManager.events.addAccessTokenExpiring(function () {
			$log.info("token expiring event");
		});

		userManager.events.addAccessTokenExpired(function () {
			setUserLoadedState(false);
			loggedInUser = null;

			if ($location.path().indexOf(anonymousUrls.forgotPassword) > -1) {
				return;
			}

			signinRedirect();
			$log.info("token expired event");
		});

		userManager.events.addSilentRenewError(function (e) {
			$log.info("silent renew error event" + e.message);
		});

		userManager.events.addUserLoaded(function (user) {
			$log.info("user loaded event", user);
			getUser().then(function () {
				loggedInUser = user;
			});

		});

		userManager.events.addUserUnloaded(function (e) {
			$log.info("user unloaded event" + e);

			setUserLoadedState(false);
			loggedInUser = null;
		});

		parseUserPermissions();

		return {
			signoutRedirect: signoutRedirect,
			getUser: getUser,
			applyAuthentication: applyAuthentication,
			redirectToLoginIfNotLoggedIn: redirectToLoginIfNotLoggedIn,
			userPermissions: userPermissions,
			userHasPermissionDeferred: userHasPermissionDeferred
		};
	}
];
