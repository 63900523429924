import { CreateEnquiryDto, ViewEnquiryDto, UpdateEnquiryDto, FeesAndChargesDto, AssignedAccommodationDto, ResidencyFinanceOptionalServiceDto, IluSettleUnitDto,
    IluStatus, SimpleResidentDto, DepartedResidentDto, UnitDto, IluAdmissionDto, DeferredManagementFeeScheduleDto, IluAccommodationFinancialEventsDto, ILUResidentDto, TransferFacilityToFacilityDto } from '../Models';
import { OptionalService } from '../../organisation/Models';

export interface IIndependentLivingService {   
    saveEnquiry: (enquiry: CreateEnquiryDto) => ng.IPromise<any>;
    viewResidents:  (notIncludingItemsAfter: any, skip: number, take: number) => ng.IPromise<Array<SimpleResidentDto>>;
    viewDepartedResidents: (notIncludingItemsAfter: any, skip: number, take: number) => ng.IPromise<Array<DepartedResidentDto>>;
    getEnquiry: (enquiryId: number, viewDepartedMode?: boolean, residentId?: number) => ng.IPromise<ViewEnquiryDto>;
    updateEnquiry: (enquiry: UpdateEnquiryDto) => ng.IPromise<any>;
    deleteEnquiry: (enquiryId: number) =>  ng.IPromise<any>;
    getAvailableUnits: ()  => ng.IPromise<any>;
    saveFeesAndCharges: (feesAndCharges: FeesAndChargesDto)  => ng.IPromise<any>;
    getFeesAndCharges: (enquiryId: number, viewDepartedMode?: boolean, residentId?: number) => ng.IPromise<FeesAndChargesDto>;
    getAccommodationFinancialEvents: (status?: IluStatus) => ng.IPromise<Array<IluAccommodationFinancialEventsDto>>;
    getUnitsByStatus: (status: IluStatus) => ng.IPromise<UnitDto[]>;
    admit: (iluAdmissionDto: IluAdmissionDto) => ng.IPromise<any>;
    correctAdmission: (iluAdmissionDto: IluAdmissionDto) => ng.IPromise<any>;
    reverseAdmission: (allocationId: number) => ng.IPromise<any>;
    reverseDeparture: (residencyId: number) => ng.IPromise<any>;
    saveAssignUnit: (assignUnit: AssignedAccommodationDto)  => ng.IPromise<any>;
    initialiseFacilityOptionalServices: (facilityOptionalService: OptionalService) => void;
    getTotalDMFPercentageForTheTimePeriod: (index : number, deferredManagementFeesSchedule : DeferredManagementFeeScheduleDto[]) => ng.IPromise<any>;
    addNote(allocationId: number, note: string): ng.IPromise<any>;     
    settleUnit: (iluSettleUnitDto: IluSettleUnitDto) => ng.IPromise<any>;
    getUnitNotes: (units: UnitDto[], events: IluAccommodationFinancialEventsDto[]) => void; 
    addResidentToEnquiry: (iLUResidentDto: ILUResidentDto) => ng.IPromise<any>;
    transferEnquiry: (transferFacilityToFacilityinfo: TransferFacilityToFacilityDto) => ng.IPromise<any>;
}