class State {
    static inject = ['createEnquiry.controller', '$state', 'authentication.service'];
    Configure = (module: ng.IModule) => {
        module.config(["$stateProvider", ($stateProvider) => {
            $stateProvider.state("createEnquiry", {
                url: "/independentLiving/createEnquiry",
                templateUrl: "app/independentLiving/partials/createEnquiry.html",
                controller: "createEnquiry.controller as vm",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return x.leadsDetails.canModify; });
                    }]
                }
            }); 

            $stateProvider.state("viewIluResidents", {
                url: "/independentLiving/viewResidents",
                template: "<view-ilu-residents></view-ilu-residents>",
                resolve: {
                    authorized: ["authentication.service", (auth) => {
                        return auth.userHasPermissionDeferred((x) => { return x.residentsDetails.canView });
                    }]
                }
            });

            $stateProvider.state("viewDepartedIluResidents", {
                url: "/independentLiving/viewDepartedIluResidents",
                template: "<view-departed-ilu-residents></view-departed-ilu-residents>",
                resolve: {
                    authorized: ["authentication.service", (auth) => {
                        return auth.userHasPermissionDeferred((x) => { return x.residentsDetails.canView; });
                    }]
                }
            });          

            $stateProvider.state("viewIndependentLivingAllocation", {
                url: "/viewIndependentLiving/{enquiryId:int}/resident/{residentId:int}/{viewDepartedMode:bool}",
                templateUrl: "app/independentLiving/partials/viewEnquiry.html",
                controller: "viewEnquiry.controller as vm",
                params: {
                    'viewDepartedMode': false
                },
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return true; });
                    }]
                }
            });

            $stateProvider.state("viewIndependentLivingAllocation.notes", {
                url: "/notes",
                template: "<notes resident-id='residentId' />",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {      
                        $scope.residentId = $stateParams.residentId;                     
                    }
                ]
            });

            $stateProvider.state("viewIndependentLivingAllocation.timeline", {
                url: "/timeline",
                template: "<resident-timeline resident-id='residentId' />",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.residentId = $stateParams.residentId;
                    }
                ],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsDetails.canView; });
                    }]
                }
            });

            $stateProvider.state("viewIndependentLivingAllocation.events", {
                url: "/events",
                template: "<resident-event-history resident-id='residentId'></resident-event-history>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.residentId = $stateParams.residentId;
                    }
                ],
                resolve: {
                    authorized: [
                        "authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.residentsDetails.canView; });
                        }
                    ]
                }
            });

            $stateProvider.state("viewIndependentLivingAllocation.transactions", {
                url: "/transactions",
                template: "<transactions resident-id='residentId' />",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.residentId = $stateParams.residentId;
                    }
                ],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canView; });
                    }]
                }
            });

            $stateProvider.state("viewIndependentLivingAllocation.finance", {
                url: "/finance",
                template: "<ilu-finance-details-view [independent-living-allocation-id]='enquiryId' [selected-resident-id]='residentId'></ilu-finance-details-view>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.enquiryId = $stateParams.enquiryId;
                        $scope.residentId = $stateParams.residentId;
                    }
                ],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.residentsFinancials.canView; });
                    }]
                },
                params: {
                    selectedResidents: null
                }
            });

            $stateProvider.state("editIndependentLivingAllocation", {
                url: "/editIndependentLiving/{enquiryId:int}/resident/{selectedResidentId:int}/{viewDepartedMode:bool}",
                templateUrl: "app/independentLiving/partials/editEnquiry.html",
                controller: "editEnquiry.controller as vm",
                params: {
                    viewDepartedMode: false,
                    selectedUnit: null,
                    selectedResidents: null,
                    doesFeeAndChargeExist: false,
                    financialTabSelected : false
                },
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return x.residentsDetails.canModify; });
                    }]
                }
            });

            $stateProvider.state("editIndependentLivingAllocation.finance", {
                //parent: "editIndependentLivingAllocation",
                url: "/finance",
                template: "<ilu-finance-details-edit [independent-living-allocation-id]='enquiryId' [selected-resident-id]='selectedResidentId'></ilu-finance-details-edit>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.enquiryId = $stateParams.enquiryId;
                        $scope.selectedResidentId = $stateParams.selectedResidentId;
                    }
                ],
                params: {
                    selectedUnit: null,
                    selectedResidents: null, 
                    doesFeeAndChargeExist: false,
                    financialTabSelected : true
                }
            });

            $stateProvider.state("viewEnquiry", {
                url: "/independentLiving/{enquiryId:int}/enquiry/{residentId:int}",
                params: {
                    residentId: null,
                },
                templateUrl: "app/independentLiving/partials/viewEnquiry.html",
                controller: "viewEnquiry.controller as vm",
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.leadsDetails.canView; });
                    }]
                }
            });

            $stateProvider.state("viewEnquiry.notes", {
                url: "/notes",
                template: "<notes resident-id='residentId' />",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {      
                        $scope.residentId = $stateParams.residentId;                     
                    }
                ]
            });

            $stateProvider.state("viewEnquiry.timeline", {
                url: "/timeline",
                template: "<resident-timeline resident-id='residentId' />",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.residentId = $stateParams.residentId;
                    }
                ],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.leadsDetails.canView; });
                    }]
                }
            });

            $stateProvider.state("viewEnquiry.events", {
                url: "/events",
                template: "<resident-event-history resident-id='residentId'></resident-event-history>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.residentId = $stateParams.residentId;
                    }
                ],
                resolve: {
                    authorized: [
                        "authentication.service", function (auth) {
                            return auth.userHasPermissionDeferred(function (x) { return x.leadsDetails.canView; });
                        }
                    ]
                }
            });

            $stateProvider.state("viewEnquiry.transactions", {
                url: "/transactions/{residentId:int}",
                template: "<transactions resident-id='residentId' allocation-id='allocationId' />",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {      
                        $scope.residentId = $stateParams.residentId;                     
                        $scope.allocationId = $stateParams.enquiryId;;
                    }
                ],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canView; });
                    }]
                }
            });

            $stateProvider.state("viewEnquiry.finance", {
                url: "/finance",
                template: "<ilu-finance-details-view [independent-living-allocation-id]='enquiryId' [selected-resident-id]='residentId'></ilu-finance-details-view>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.enquiryId = $stateParams.enquiryId;
                        $scope.residentId = $stateParams.residentId;
                    }
                ],
                resolve: {
                    authorized: ["authentication.service", function (auth) {
                        return auth.userHasPermissionDeferred(function (x) { return x.leadsFinancials.canView; });
                    }]
                },
                params: {
                    selectedResidents: null
                }
            });

            $stateProvider.state("editEnquiry.finance", {
                url: "/finance",
                template: "<ilu-finance-details-edit [independent-living-allocation-id]='enquiryId' [selected-resident-id]='selectedResidentId'></ilu-finance-details-edit>",
                controller: [
                    "$stateParams", "$scope", function ($stateParams, $scope) {
                        $scope.enquiryId = $stateParams.enquiryId;
                        $scope.selectedResidentId = $stateParams.selectedResidentId;
                    }
                ],
                params: {
                    selectedUnit: null,
                    selectedResidents: null, 
                    doesFeeAndChargeExist: false,
                    financialTabSelected : true
                }
            });

            $stateProvider.state("editEnquiry", {
                url: "/independentLiving/editEnquiry/{enquiryId:int}/Details/{selectedResidentId:int}",
                templateUrl: "app/independentLiving/partials/editEnquiry.html",
                controller: "editEnquiry.controller as vm",
                params: {
                    selectedUnit: null,
                    selectedResidents: null,
                    doesFeeAndChargeExist: false,
                    financialTabSelected : false,
                    enableAddingNewResident : false
                },
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return x.leadsDetails.canModify; });
                    }]
                }
            });

            $stateProvider.state("markDepartedResidentAsIluEnquiry", {
                url: "/markAsIluEnquiry/{enquiryId:int}/{residentId:int}",
                templateUrl: "app/independentLiving/partials/markDepartedResidentAsIluEnquiry.html",
                controller: "markDepartedResidentAsIluEnquiry.controller as vm"
            });
        
        }]);
    }
}

export = State;
