"use strict";
const moment = require("moment");
const _ = require("underscore");

module.exports = [
  "$scope",
  "residents.service",
  "referenceData.service",
  "notification.service",
  "$state",
  "dialog.service",
  "facility.service",
  "featureFlags.service",
  "Titles",
  "dateFormatting.service",
  "$anchorScroll",
  "$location",
  "utilities.service",
  "SpecialisedCareList",
  "$timeout",
  function (
    $scope,
    residentsService,
    referenceDataService,
    notification,
    $state,
    dialogService,
    facilityService,
    featureFlagsService,
    Titles,
    dateFormattingService,
    $anchorScroll,
    $location,
    utilitiesService,
    SpecialisedCareList,
    $timeout
  ) {
    $scope.selectedFacilityName = facilityService.selectedFacility.name;
    $scope.selectedFacilityType = facilityService.selectedFacility.facilityType;
    $scope.isTechOneFacility = facilityService.selectedFacility.isBillingIntegratedFacility;
    $scope.pageVariables = {
      hasPreEntryLeaveDate: false,
      preEntryDays: null,
      selectedBillPayer: null,
    };
    $scope.billPayers = [];
    $scope.billInvoiceMethod = null;
    $scope.selectedCountry = {};
    $scope.selectedCountry.otherContacts = [];
    $scope.enquirySources = [];
    $scope.religions = [];
    $scope.enablePrivateResidents = featureFlagsService.featureFlags.systemConfigurationFlags.enablePrivateResidents;
    $scope.careRecipientIdFieldMaxLength = 10;
    $scope.residentialCareIdFieldMaxLength = 10;
    $scope.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    $scope.expiryYears = [];
    $scope.dropdownSettings = {
      textField: "name",
      itemsShowLimit: 1,
      addAllOption: false,
      enableCheckAll: false,
      showClearSelectedItems: true,
    };
    $scope.specialisedCareList = [];
    $scope.isCheckboxTouched = false;
    $scope.ccEmailDelimiter = ";";
    $scope.ccIndex = 0;
    $scope.emailAddresses = [{ name: "email", value: "" }];
    $scope.invalidCCEmailLengthDisplayed;
    $scope.ccEmails = "";
    $scope.ccEmailLengthLimit = 150;
    $scope.showPostalValsidation = false;
    $scope.contact = {};
    $scope.triggerUniqueValidation = false;
    var vm = this;

    (function pupulatespecialisedCareList() {
      $scope.specialisedCareList.push({ id: SpecialisedCareList["Court managed"], name: "Court managed" });
      $scope.specialisedCareList.push({ id: SpecialisedCareList["Homeless"], name: "Homeless" });
      $scope.specialisedCareList.push({ id: SpecialisedCareList["NDIS"], name: "NDIS" });
      $scope.specialisedCareList.push({ id: SpecialisedCareList["Safety net"], name: "Safety net" });
      $scope.specialisedCareList.push({ id: SpecialisedCareList["TCP"], name: "TCP" });
    })();

    function populateExpiryYears() {
      const currentYear = new Date().getFullYear();
      if ($scope.resident.medicareExpiryYear != 0 && $scope.resident.medicareExpiryYear != null && $scope.resident.medicareExpiryYear < currentYear - 20) {
        $scope.expiryYears.push($scope.resident.medicareExpiryYear);
      }
      for (let i = currentYear - 20; i < currentYear + 21; i++) {
        $scope.expiryYears.push(i);
      }
    }

    var unregisterResidentWatcher = $scope.$watch("resident", function () {
      if ($scope.resident) {
        if ($scope.resident.preEntryLeaveDateUtc !== null) {
          $scope.pageVariables.hasPreEntryLeaveDate = true;
          $scope.pageVariables.preEntryDays = moment($scope.resident.requestedAdmissionDateUtc).diff(moment($scope.resident.preEntryLeaveDateUtc), "days").toString();
        }

        populateExpiryYears();

        if ($state.current.name === "editResident" || $state.current.name === "editLead" || $state.current.name === "editEnquiry" || $state.current.name === "editIndependentLivingAllocation") {
          var primaryContact = getBillPayer($scope.resident.primaryContact);
          var secondaryContact = getBillPayer($scope.resident.secondaryContact);

          var residentName = "";
          if ($scope.resident.firstName && $scope.resident.lastName) {
            residentName = $scope.resident.firstName + " " + $scope.resident.lastName;
          }

          if ($scope.resident.billPayerDetail === null) {
            $scope.resident.billPayerDetail = {};
          }

          $scope.billPayers = [
            {
              payerType: "empty",
              name: null,
              exists: true,
            },
            {
              payerType: "Resident",
              payerTypeName: "Resident",
              name: residentName,
              billPayerDetail: $scope.resident.billPayerDetail,
              contact: getResidentContact(),
              exists: true,
            },
            {
              payerType: "PrimaryContact",
              payerTypeName: "Primary contact",
              name: primaryContact.name,
              billPayerDetail: primaryContact.contact ? primaryContact.contact.billPayerDetail : null,
              contact: primaryContact.contact,
              exists: primaryContact.exists,
            },
            {
              payerType: "SecondaryContact",
              payerTypeName: "Secondary contact",
              name: secondaryContact.name,
              billPayerDetail: secondaryContact.contact ? secondaryContact.contact.billPayerDetail : null,
              contact: secondaryContact.contact,
              exists: secondaryContact.exists,
            },
          ];
          if ($scope.resident.billPayerType) {
            $scope.pageVariables.selectedBillPayer = _.find($scope.billPayers, { payerType: $scope.resident.billPayerType });
            $scope.contact = $scope.pageVariables.selectedBillPayer.contact;
            setInvoiceMethodSelectedFlag();
          }

          //set selected countries for resident
          $scope.selectedCountry.resident = $scope.resident.country;

          if ($scope.resident.primaryContact) {
            $scope.selectedCountry.primaryContact = $scope.resident.primaryContact.country;
          }

          if ($scope.resident.secondaryContact) {
            $scope.selectedCountry.secondaryContact = $scope.resident.secondaryContact.country;
          }

          if ($scope.resident.otherContacts && $scope.resident.otherContacts.length > 0) {
            for (var index = 0; index < $scope.resident.otherContacts.length; index++) {
              $scope.selectedCountry.otherContacts[index] = $scope.resident.otherContacts[index].country;
            }
          }
        }
        $scope.populateCcEmailAddresses();
        unregisterResidentWatcher();
      }

      if ($location.$$hash != null) {
        $scope.gotoSelectedContact($location.$$hash);
      }
    });

    $scope.viewAccr = function (isDirty) {
      dialogService.openDialog("app/accr/partials/viewAccrDialog.html", "viewAccrDialog.controller", {
        residentId: $scope.resident.id,
        isDepartedResident: $scope.resident.isDeparted,
        isBackgroundFormDirty: isDirty,
      });
    };

    $scope.openGetCridDialog = function () {
      vm.resident = $scope.resident;
      dialogService.openDialog("app/residents/partials/getCridDialog.html", "getCridDialog.controller", {
        resident: {
          id: vm.resident.id,
          firstName: vm.resident.firstName,
          lastName: vm.resident.lastName,
          careType: vm.resident.careType,
          sex: vm.resident.sex,
          dateOfBirth: vm.resident.dateOfBirth,
          state: vm.resident.state,
          postCode: vm.resident.postCode,
          careRecipientId: vm.resident.careRecipientId,
        },
      });
    };

    function getBillPayer(contact) {
      var result = {
        name: "Add",
        contact: null,
        exists: false,
      };

      if (!contact) {
        return result;
      }

      if (!contact.firstName && !contact.lastName) {
        result.name = "Add";
        result.exists = false;
      } else {
        result.name = contact.firstName + " " + contact.lastName;
        result.exists = true;
      }

      result.contact = contact;
      return result;
    }

    $scope.onBillPayerSelected = function (item) {
      if (!item.exists) {
        dialogService.openStaticDialog("app/residents/partials/addContactDialog.html", "addContactDialog.controller", {
          resident: $scope.resident,
          payerType: $scope.pageVariables.selectedBillPayer.payerType,
          payerTypeName: $scope.pageVariables.selectedBillPayer.payerTypeName,
          state: $state.current.name,
          callBack: $scope.setBillPayerContact,
          persist: false,
          cancelCallback: $scope.cancelBillPayerSelection,
        });

        // $scope.contact = $scope.pageVariables.selectedBillPayer.contact;
        resetIsInvoiceEmailIfNoEmail();
        resetIsInvoicePostIfNoPostalAddress();
        setInvoiceMethodSelectedFlag();
      } else {
        $scope.resident.billPayerType = $scope.pageVariables.selectedBillPayer.payerType;
        var billPayer = _.find($scope.billPayers, { payerType: $scope.resident.billPayerType });
        if ($scope.resident.billPayerType === "PrimaryContact") {
          $scope.resident.primaryContact.billPayerDetail = {};

          if ($scope.pageVariables.selectedBillPayer.billPayerDetail != undefined) {
            $scope.resident.primaryContact.billPayerDetail = $scope.pageVariables.selectedBillPayer.billPayerDetail;
          }
          billPayer.billPayerDetail = $scope.resident.primaryContact.billPayerDetail;
        } else if ($scope.resident.billPayerType === "SecondaryContact") {
          $scope.resident.secondaryContact.billPayerDetail = {};
          if ($scope.pageVariables.selectedBillPayer.billPayerDetail != undefined) {
            $scope.resident.secondaryContact.billPayerDetail = $scope.pageVariables.selectedBillPayer.billPayerDetail;
          }
          billPayer.billPayerDetail = $scope.resident.secondaryContact.billPayerDetail;
        } else if ($scope.resident.billPayerType === "Resident") {
          $scope.resident.billPayerDetail = {};
          if ($scope.pageVariables.selectedBillPayer.billPayerDetail != undefined) {
            $scope.resident.billPayerDetail = $scope.pageVariables.selectedBillPayer.billPayerDetail;
          }
          billPayer.billPayerDetail = $scope.resident.billPayerDetail;
        }

        resetIsInvoiceEmailIfNoEmail();
        resetIsInvoicePostIfNoPostalAddress();
        setInvoiceMethodSelectedFlag();
      }
      $scope.populateCcEmailAddresses();
      $scope.contact = $scope.pageVariables.selectedBillPayer.contact;
    };

    function resetIsInvoiceEmailIfNoEmail() {
      if ($scope.pageVariables.selectedBillPayer && $scope.pageVariables.selectedBillPayer.billPayerDetail) {
        if ($scope.pageVariables.selectedBillPayer.billPayerDetail.isInvoiceEmail && $scope.pageVariables.selectedBillPayer.contact && $scope.pageVariables.selectedBillPayer.contact.email === "") {
          $scope.pageVariables.selectedBillPayer.billPayerDetail.isInvoiceEmail = false;
        }
      }
    }

    function resetIsInvoicePostIfNoPostalAddress() {
      if ($scope.pageVariables.selectedBillPayer && $scope.pageVariables.selectedBillPayer.billPayerDetail) {
        if ($scope.pageVariables.selectedBillPayer.billPayerDetail.isInvoicePost && $scope.pageVariables.selectedBillPayer.contact && !$scope.hasContactPost()) {
          $scope.pageVariables.selectedBillPayer.billPayerDetail.isInvoicePost = false;
        }
      }
    }

    function setInvoiceMethodSelectedFlag() {
      if ($scope.pageVariables.selectedBillPayer && $scope.pageVariables.selectedBillPayer.billPayerDetail) {
        if (!$scope.pageVariables.selectedBillPayer.billPayerDetail.isInvoiceEmail && !$scope.pageVariables.selectedBillPayer.billPayerDetail.isInvoicePost) {
          $scope.billInvoiceMethod = null;
        } else {
          $scope.billInvoiceMethod = true;
        }
      } else {
        $scope.billInvoiceMethod = null;
      }
    }

    $scope.cancelBillPayerSelection = () => {
      if ($scope.resident.billPayerType) {
        $scope.pageVariables.selectedBillPayer = _.find($scope.billPayers, { payerType: $scope.resident.billPayerType });
        setInvoiceMethodSelectedFlag();
      } else {
        $scope.pageVariables.selectedBillPayer = _.find($scope.billPayers, { payerType: "empty" });
      }
    };

    $scope.setBillPayerContact = function (payerType, contact) {
      contact.billPayerDetail = {};

      var billPayer = _.find($scope.billPayers, { payerType: payerType });
      if (payerType === "PrimaryContact") {
        $scope.resident.primaryContact = contact;
        billPayer.billPayerDetail = $scope.resident.primaryContact.billPayerDetail;
        $scope.resident.billPayerType = "PrimaryContact";
      } else {
        $scope.resident.secondaryContact = contact;
        billPayer.billPayerDetail = $scope.resident.secondaryContact.billPayerDetail;
        $scope.resident.billPayerType = "SecondaryContact";
      }

      billPayer.name = contact.firstName + " " + contact.lastName;
      billPayer.contact = contact;
      billPayer.exists = true;
      $scope.pageVariables.selectedBillPayer = billPayer;
      $scope.contact = contact;
    };

    $scope.invoicePostChecked = function (checked) {
      if ($scope.pageVariables && $scope.pageVariables.selectedBillPayer && $scope.pageVariables.selectedBillPayer.billPayerDetail) {
        $scope.pageVariables.selectedBillPayer.billPayerDetail.isInvoicePost = checked;
        $scope.isCheckboxTouched = true;
      } else {
        $scope.pageVariables.selectedBillPayer = { billPayerDetail: { isInvoicePost: checked } };
        $scope.isCheckboxTouched = false;
      }
      if (!$scope.pageVariables.selectedBillPayer.billPayerDetail?.isInvoiceEmail && !checked) {
        $scope.billInvoiceMethod = null;
      } else {
        $scope.billInvoiceMethod = checked || $scope.pageVariables.selectedBillPayer.billPayerDetail?.isInvoiceEmail;
      }
    };

    $scope.invoiceEmailChecked = function (checked) {
      if ($scope.pageVariables && $scope.pageVariables.selectedBillPayer && $scope.pageVariables.selectedBillPayer.billPayerDetail) {
        $scope.pageVariables.selectedBillPayer.billPayerDetail.isInvoiceEmail = checked;
        $scope.isCheckboxTouched = true;
      } else {
        $scope.pageVariables.selectedBillPayer = { billPayerDetail: { isInvoiceEmail: checked } };
        $scope.isCheckboxTouched = false;
      }
      if (!checked && !$scope.pageVariables.selectedBillPayer.billPayerDetail?.isInvoicePost) {
        $scope.billInvoiceMethod = null;
      } else {
        $scope.billInvoiceMethod = checked || $scope.pageVariables.selectedBillPayer.billPayerDetail?.isInvoicePost;
      }
    };

    if ($state.current.name === "editResident" || $state.current.name === "editLead" || $state.current.name === "editEnquiry" || $state.current.name === "editIndependentLivingAllocation") {
      referenceDataService.getRelationships().then(
        function (data) {
          $scope.relationships = data;
        },
        function () {
          notification.error("Unable to display relationship types. Either your computer is offline, or Resident Manager is having problems of its own.");
        }
      );

      referenceDataService.getCountries().then(
        function (data) {
          $scope.countries = data;
          var emptyItem = {
            id: null,
            displayName: "",
          };
          $scope.countries.splice(0, 0, emptyItem);
        },
        function () {
          notification.error("Unexpected error occurred while loading the relationships.");
        }
      );

      $scope.titleOptions = Titles;
    } else if ($state.current.name === "viewLead" || $state.current.name === "viewResident" || $state.current.name === "viewEnquiry" || $state.current.name === "viewIndependentLivingAllocation") {
      var notIncludingItemsAfter = null;
      var take = 2;
      var skip = 0;

      residentsService.getResidentNotes($scope.resident.id, notIncludingItemsAfter, skip, take).then(
        function (data) {
          $scope.residentNotes = data.result;
        },
        function () {
          notification.error("Unexpected error occurred while loading the notes.");
        }
      );

      $scope.residencyId = $scope.resident.residencyId;
      if ($state.current.name === "viewEnquiry" || $state.current.name === "viewIndependentLivingAllocation") {
        if ($scope.resident.billPayerType) {
          var payerType = $scope.resident.billPayerType;
          var payerTypeName = "";
          var payerName = "";
          var billPayerDetails = null;
          var contact = null;
          if ($scope.resident.billPayerType == "Resident") {
            payerTypeName = "Resident";
            payerName = $scope.resident.firstName + " " + $scope.resident.lastName;
            billPayerDetails = $scope.resident.billPayerDetail;
            contact = getResidentContact();
          } else if ($scope.resident.billPayerType == "PrimaryContact") {
            payerTypeName = "Primary contact";
            payerName = $scope.resident.primaryContact.firstName + " " + $scope.resident.primaryContact.lastName;
            billPayerDetails = $scope.resident.primaryContact.billPayerDetail;
            contact = $scope.resident.primaryContact;
          } else if ($scope.resident.billPayerType == "SecondaryContact") {
            payerTypeName = "Secondary contact";
            payerName = $scope.resident.secondaryContact.firstName + " " + $scope.resident.secondaryContact.lastName;
            billPayerDetails = $scope.resident.secondaryContact.billPayerDetail;
            contact = $scope.resident.secondaryContact;
          }
          $scope.pageVariables.selectedBillPayer = {
            payerType: payerType,
            payerTypeName: payerTypeName,
            name: payerName,
            billPayerDetail: billPayerDetails,
            contact: contact,
          };
        }
      }
    }

    $scope.hasContactEmail = function () {
      if ($scope.pageVariables.selectedBillPayer) {
        if ($scope.pageVariables.selectedBillPayer.contact) {
          return $scope.pageVariables.selectedBillPayer.contact.email;
        } else {
          return false;
        }
      }

      return false;
    };

    $scope.hasContactPost = function () {
      if ($scope.pageVariables.selectedBillPayer) {
        if ($scope.pageVariables.selectedBillPayer.contact) {
          return (
            $scope.pageVariables.selectedBillPayer.contact.street1 ||
            $scope.pageVariables.selectedBillPayer.contact.street2 ||
            $scope.pageVariables.selectedBillPayer.contact.suburb ||
            $scope.pageVariables.selectedBillPayer.contact.stateDescription ||
            $scope.pageVariables.selectedBillPayer.contact.postCode ||
            $scope.pageVariables.selectedBillPayer.contact.country
          );
        } else {
          return false;
        }
      }

      return false;
    };

    $scope.addEditAddress = function () {
      dialogService.openDialog("app/residents/partials/addEditContactAddressDialog.html", "addEditContactDetailDialog.controller", {
        resident: $scope.resident,
        payerType: $scope.pageVariables.selectedBillPayer.payerType,
        payerTypeName: $scope.pageVariables.selectedBillPayer.payerTypeName,
        contactField: "address",
        state: $state.current.name,
        callBack: $scope.setContactEmailAddress,
        persist: false,
      });
    };

    $scope.setContactEmailAddress = function (payerType, contact) {
      var countryDisplayName = contact.countryId ? _.findWhere($scope.countries, { id: contact.countryId }).displayName : undefined;
      switch (payerType) {
        case "Resident": {
          $scope.resident.email = contact.email;
          $scope.resident.street1 = contact.street1;
          $scope.resident.street2 = contact.street2;
          $scope.resident.suburb = contact.suburb;
          $scope.resident.state = contact.state;
          $scope.resident.postCode = contact.postCode;
          $scope.resident.countryId = contact.countryId;
          $scope.selectedCountry.resident = countryDisplayName;

          break;
        }
        case "PrimaryContact": {
          $scope.resident.primaryContact = contact;
          $scope.selectedCountry.primaryContact = countryDisplayName;

          break;
        }
        case "SecondaryContact": {
          $scope.resident.secondaryContact = contact;
          $scope.selectedCountry.secondaryContact = countryDisplayName;

          break;
        }
      }

      var billPayer = _.find($scope.billPayers, { payerType: payerType });
      contact.stateDescription = utilitiesService.getStateDescription(contact.state);
      billPayer.contact = contact;
      billPayer.exists = true;
    };

    function getResidentContact() {
      var contact = {};
      contact.email = $scope.resident.email;

      contact.street1 = $scope.resident.street1;
      contact.street2 = $scope.resident.street2;
      contact.suburb = $scope.resident.suburb;
      contact.stateDescription = $scope.resident.stateDescription;
      contact.postCode = $scope.resident.postCode;
      contact.country = $scope.resident.country;

      return contact;
    }

    $scope.onAssignBedSaveCallBack = function (roomChangeDto) {
      if ($scope.resident.assignedAccommodationAndBed) {
        $scope.resident.assignedAccommodationAndBed.isBedSwap = roomChangeDto.isBedSwap;
        $scope.resident.assignedAccommodationAndBed.bedId = roomChangeDto.selectedBedId;
        $scope.resident.assignedAccommodationAndBed.effectiveDate = roomChangeDto.effectiveDate;
        $scope.resident.assignedAccommodationAndBed.roomNumber = roomChangeDto.roomNumber;
        $scope.resident.assignedAccommodationAndBed.bedNumber = roomChangeDto.bedNumber;
        $scope.resident.assignedAccommodationAndBed.location = roomChangeDto.location;
        $scope.resident.assignedAccommodationAndBed.accommodationType = roomChangeDto.accommodationType;
      }

      if ($scope.selectedRoom) {
        $scope.selectedRoom.roomNumber = roomChangeDto.roomNumber;
        $scope.selectedRoom.location = roomChangeDto.location;
        $scope.selectedRoom.roomType = roomChangeDto.accommodationType;
      }
    };

    $scope.launchReturnFromLeaveDialog = function () {
      dialogService.openDialog("app/residents/partials/returnFromLeaveDialog.html", "returnFromLeaveDialog.controller", {
        resident: $scope.resident,
        fromILU: $state.current.name == "viewIndependentLivingAllocation",
      });
    };

    $scope.launchEditLeaveDialog = function () {
      dialogService.openDialog("app/residents/partials/sendOnLeaveDialog.html", "sendOnLeaveDialog.controller", {
        resident: $scope.resident,
        fromILU: $state.current.name == "viewIndependentLivingAllocation",
        origin: "residentDetails",
      });
    };

    $scope.openAssignBedDialog = function () {
      dialogService.openDialog("app/residents/partials/assignBedDialog.html", "assignBedDialog.controller", {
        resident: $scope.resident,
        residentId: $scope.resident.id,
        expectedDepartureDate: $scope.resident.expectedDepartureDateUtc,
        origin: "residentDetails",
        onSaveAssignedBedEventCallBack: $scope.onAssignBedSaveCallBack,
      });
    };

    $scope.minRequestedAdmissionDate = function () {
      return moment($scope.resident.createdOnUtc).startOf("day").toDate();
    };

    $scope.minRequestedDepartureDate = function () {
      return moment($scope.resident.requestedAdmissionDateUtc).add(1, "d").startOf("day").toDate();
    };

    $scope.getHospitalLeaveMessage = function () {
      var days = dateFormattingService.snapToClosestUTCMidnight(moment().startOf("day")).diff(moment.utc($scope.resident.leave.startDateUtc), "days");
      switch (days) {
        case 0:
          return "";
        case 1:
          return " since yesterday,";
        default:
          return " since " + days + " days ago,";
      }
    };

    $scope.clearPreEntryLeaveDate = function () {
      if (!$scope.pageVariables.hasPreEntryLeaveDate) {
        delete $scope.resident.preEntryLeaveDateUtc;
        delete $scope.pageVariables.preEntryDays;
      }
    };

    $scope.preEntryDate = function () {
      if ($scope.pageVariables.preEntryDays && $scope.resident.careType === "Permanent" && $scope.resident.requestedAdmissionDateUtc) {
        $scope.resident.preEntryLeaveDateUtc = moment($scope.resident.requestedAdmissionDateUtc).subtract($scope.pageVariables.preEntryDays, "d").zone("Z").startOf("day").toDate();
        return $scope.resident.preEntryLeaveDateUtc;
      }

      return null;
    };

    $scope.minExpectedDepartureDate = function () {
      return moment($scope.resident.admissionDateUtc).add(1, "d").startOf("day").toDate();
    };

    $scope.isResidentRequiredField = function () {
      if ($scope.resident) {
        return $scope.resident.isLead !== true;
      }
      return undefined;
    };

    $scope.isMemberNumberRequired = () => {
      if ($scope.resident) {
        if ($scope.resident.medicareNumber && $scope.resident.medicareNumber != "") return true;
      }
      return false;
    };

    $scope.isProspectRequiredField = function () {
      if ($scope.resident) {
        return $scope.resident.isResidentConnectedToExternalSystem === true;
      }
      return undefined;
    };

    $scope.isBillPayer = function (billPayerType) {
      return !$scope.resident ? false : $scope.resident.billPayerType === billPayerType;
    };

    $scope.isInvoiceEmailRequired = function (billPayerType) {
      return !$scope.resident ? false : $scope.resident.billPayerType === billPayerType && $scope.pageVariables.selectedBillPayer.billPayerDetail?.isInvoiceEmail;
    };

    $scope.isInvoiceAddressRequired = function (billPayerType) {
      return !$scope.resident ? false : $scope.resident.billPayerType === billPayerType && $scope.pageVariables.selectedBillPayer.billPayerDetail?.isInvoicePost;
    };

    $scope.validateOnSave = function (isValid) {
      if ($scope.pageVariables.selectedBillPayer?.billPayerDetail?.isInvoicePost && !$scope.hasContactPost()) {
        isValid = false;
      }
      if ($scope.pageVariables.selectedBillPayer) {
        $scope.resident = $scope.setResidentContactDataBeforeUpdate();
      }
      $scope.save({ isValid: isValid }); // Pass the isValid as an object
    };

    $scope.redirectToFinanceFeeBillPayer = function () {
      var goToState = $state.current.name === "viewResident" || $state.current.name === "editResident" ? "viewResident.financialsFees" : "viewLead.financialsFees";
      $state.go(goToState, { residentId: $scope.resident.id, "#": "billPayer" });
    };

    $scope.redirectToFinanceDetail = function () {
      var goToState =
        $state.current.name === "viewIndependentLivingAllocation" || $state.current.name === "editIndependentLivingAllocation" ? "viewIndependentLivingAllocation.finance" : "viewEnquiry.finance";
      $state.go(goToState, { enquiryId: $state.params.enquiryId, residentId: $scope.resident.id, "#": "unitDetail" }, { reload: true });
    };

    $scope.openlegalNoticeReceivedDialog = function () {
      dialogService.openDialog("app/residents/partials/legalNoticeReceivedDialog.html", "legalNoticeReceivedDialog.controller", {
        residencyId: $scope.resident.residencyId,
        departureDateUtc: $scope.resident.departureDateUtc,
      });
    };

    $scope.redirectToNote = function (residentNoteId) {
      if ($scope.selectedFacilityType === "IndependentLiving") {
        if ($scope.resident.isAdmitted || $scope.resident.isDeparted) {
          $state.go("viewIndependentLivingAllocation.notes", { "#": residentNoteId });
        } else {
          $state.go("viewEnquiry.notes", { residentId: $scope.resident.id, "#": residentNoteId });
        }
      } else {
        $state.go($scope.resident.isLead === true ? "viewLead.notes" : "viewResident.notes", { "#": residentNoteId });
      }
    };

    $scope.addOtherContact = () => {
      var otherContact = {};
      $scope.resident.otherContacts.push(otherContact);
      var rowIndex = $scope.resident.otherContacts.length - 1;
      $scope.gotoSelectedContact("otherContact" + "-" + rowIndex);
    };

    $scope.resetNameOfCare = () => {
      $scope.resident.nameOfCare = "";
    };

    $scope.removeOtherContact = (index) => {
      if ($scope.resident.otherContacts.length === 0) {
        return;
      }
      $scope.resident.otherContacts.splice(index, 1);
    };

    $scope.gotoSelectedContact = function (contactSection) {
      //set the anchor point using an id
      $location.hash(contactSection);
      //scroll to the new anchor point
      $anchorScroll();
    };

    $scope.isDisplayContact = (contact) => {
      var isContactDefined;
      isContactDefined = $scope.isContactHasValue(contact);
      return isContactDefined;
    };

    $scope.isDisplayContactList = function () {
      var isPrimaryContactDefined = $scope.isDisplayContact($scope.resident?.primaryContact);
      var isSecondaryContactDefined = $scope.isDisplayContact($scope.resident?.secondaryContact);
      var isOtherContactsDefined = false;
      var rowIndex = 0;
      for (rowIndex = 0; rowIndex < $scope.resident?.otherContacts.length; rowIndex++) {
        isOtherContactsDefined = $scope.isDisplayContact($scope.resident?.otherContacts[rowIndex]);
        if (isOtherContactsDefined) {
          break;
        }
      }
      return isPrimaryContactDefined || isSecondaryContactDefined || isOtherContactsDefined;
    };

    $scope.isContactHasValue = function (contactObject) {
      if (contactObject != null) {
        var contact = {
          title: contactObject.title,
          firstName: contactObject.firstName,
          lastName: contactObject.lastName,
          phoneNumber: contactObject.phoneNumber,
          phoneNumberAlternate: contactObject.phoneNumberAlternate,
          contactNotes: contactObject.contactNotes,
        };
        const isEmpty = contact == null || Object.values(contact).every((x) => x === null || x === "" || x === undefined);
        var status = false;
        if (isEmpty) {
          if (contactObject.relationships != null && contactObject.relationships.length > 0) {
            return true;
          }
        }
        return !isEmpty || status;
      }
      return false;
    };

    $scope.onCountrySelectCallback = function ($item, type, indexOfOtherContacts = 0) {
      switch (type) {
        case "resident":
          $scope.selectedCountry.resident = $item.displayName;
          break;

        case "primaryContact":
          $scope.selectedCountry.primaryContact = $item.displayName;
          break;

        case "secondaryContact":
          $scope.selectedCountry.secondaryContact = $item.displayName;
          break;

        case "otherContacts":
          $scope.selectedCountry.otherContacts[indexOfOtherContacts] = $item.displayName;
          break;

        default:
          break;
      }
    };

    referenceDataService.getEnquirySources().then(
      function (data) {
        $scope.enquirySources = data;
      },
      function () {
        notification.error("Unable to display enquiry sources. Either your computer is offline, or Resident Manager is having problems of its own.");
      }
    );

    $scope.addEnquirySource = function (text) {
      $scope.$apply(function () {
        var object = { name: text, id: "new_" + text };
        $scope.enquirySources.push(object);
        $scope.resident.enquirySources.push(object);
      });
    };

    referenceDataService.getReligions().then(
      function (data) {
        $scope.religions = data;
      },
      function () {
        notification.error("Unable to display religions. Either your computer is offline, or Resident Manager is having problems of its own.");
      }
    );

    $scope.addReligion = function (text) {
      $scope.$apply(function () {
        var object = { name: text, id: "new_" + text };
        $scope.religions.push(object);
        $scope.resident.religions.push(object);
      });
    };

    $scope.populateCcEmailAddresses = () => {
      $scope.resetCcEmails();
      let ccEmails = [];
      let ccEmail = "";
      switch ($scope.pageVariables.selectedBillPayer?.payerType) {
        case "Resident":
          ccEmail = $scope.resident?.billPayerDetail?.ccEmail;
          break;
        case "PrimaryContact":
          ccEmail = $scope.resident?.primaryContact?.billPayerDetail?.ccEmail;
          break;
        case "SecondaryContact":
          ccEmail = $scope.resident?.secondaryContact?.billPayerDetail?.ccEmail;
          break;
        default:
          break;
      }

      if (ccEmail) {
        ccEmails = ccEmail?.split($scope.ccEmailDelimiter);
        ccEmails?.forEach((ccEmail) => {
          $scope.addNewCcEmailAddress(ccEmail);
        });
      }
    };

    $scope.resetCcEmails = () => {
      $scope.emailAddresses = [{ name: "email", value: "" }];
      $scope.ccIndex = 0;
    };

    $scope.addNewCcEmailAddress = function (ccEmailId = "") {
      $scope.emailAddresses.push({ name: "ccEmail" + $scope.ccIndex, value: ccEmailId });
      $scope.ccIndex++;

      $scope.getCcEmailIds();
    };


    $scope.getCcEmailIds = () => {
      var emails = $scope.emailAddresses
        .map(function (item) {
          return item.value;
        })
        .filter(Boolean) 
        .toString();

      if (emails.length > 0) {
        emails = emails.charAt(emails.length - 1) == "," ? emails.slice(0, -1) : emails;
        $scope.ccEmails = emails.charAt(0) == "," ? emails.slice(1) : emails;
      } else {
        $scope.ccEmails = '';
      }
      // If CC Email length exceed to 150 characters then show validation message.
      if ($scope.ccEmails.length > $scope.ccEmailLengthLimit) {
        $scope.invalidCCEmailLengthDisplayed = true;
      } else {
        $scope.invalidCCEmailLengthDisplayed = false;
      }
      $scope.setCcEmails({ ccEmails: $scope.ccEmails });
    };

    $scope.setResidentContactDataBeforeUpdate = () => {
      let resident = {};
      angular.extend(resident, $scope.resident);
      resident.billPayerType = $scope.pageVariables.selectedBillPayer?.payerType;

      switch ($scope.pageVariables.selectedBillPayer.payerType) {
        case "Resident": {
          resident.email = $scope.contact.email;
          resident.street1 = $scope.contact.street1;
          resident.street2 = $scope.contact.street2;
          resident.suburb = $scope.contact.suburb;
          resident.state = $scope.contact.state;
          resident.postCode = $scope.contact.postCode;
          resident.countryId = $scope.contact.countryId;
          resident.country = $scope.contact.country;
          if (resident.billPayerDetail) {
            resident.billPayerDetail.ccEmail = $scope.contact?.billPayerDetail?.ccEmail;
          } else {
            resident.billPayerDetail = { ccEmail: $scope.contact?.billPayerDetail?.ccEmail };
          }
          break;
        }
        case "PrimaryContact": {
          resident.primaryContact = $scope.contact;
          resident.primaryContact.isBillPayer = true;
          if (resident.primaryContact.billPayerDetail) {
            resident.primaryContact.billPayerDetail.ccEmail = $scope.contact?.billPayerDetail?.ccEmail;
          } else {
            resident.primaryContact.billPayerDetail = { ccEmail: $scope.contact?.billPayerDetail?.ccEmail };
          }

          break;
        }
        case "SecondaryContact": {
          resident.secondaryContact = $scope.contact;
          resident.secondaryContact.isBillPayer = true;
          if (resident.secondaryContact.billPayerDetail) {
            resident.secondaryContact.billPayerDetail.ccEmail = $scope.contact?.billPayerDetail?.ccEmail;
          } else {
            resident.secondaryContact.billPayerDetail = { ccEmail: $scope.contact?.billPayerDetail?.ccEmail };
          }
          break;
        }
      }

      resident.stateDescription = utilitiesService.getStateDescription($scope.contact.state);

      return resident;
    };

    $scope.deleteRow = function (index) {
      if ($scope.emailAddresses.length === 0) {
        return;
      }

      dialogService.openActionDialog(
        "Delete email address",
        "Are you sure you want to delete this email address?",
        "hardship-delete",
        "Delete",
        () => {
          $scope.emailAddresses.splice(index, 1);
          $scope.getCcEmailIds();
        },
        "btn-danger btn-inline-block-xsm"
      );
    };

    $scope.markFormValid = function (event) {
      if (event.isUnique) {
        $scope.makeFormValid();
      }
    };

    // $scope.setCcEmails = function() {
    //   $scope.ccEmails = $scope.ccEmails.replace(/,/g, $scope.ccEmailDelimiter);
    //   switch ($scope.selectedResident.billPayerType) {
    //     case BillPayerType.Resident:
    //       this.selectedResident.billPayerDetail.ccEmail = this.ccEmails;
    //       break;
    //     case BillPayerType.PrimaryContact:
    //       this.selectedResident.primaryContact.billPayerDetail.ccEmail = this.ccEmails;
    //       break;
    //     case BillPayerType.SecondaryContact:
    //       this.selectedResident.secondaryContact.billPayerDetail.ccEmail = this.ccEmails;
    //       break;
    //     default:
    //       break;
    //   }
    // }

    $scope.makeFormValid = function () {
      angular.forEach($scope.editResidentForm, function (field, key) {
        if (key[0] !== "$" && key.indexOf("ccEmail") > -1) {
          field.$setValidity("customError", true); // Clear error
          field.$setPristine(); 
          field.$setUntouched(); 
        }
      });
      $scope.triggerUniqueValidation = true;

      // Optionally, reset after validation is triggered
      $timeout(function () {
        $scope.triggerUniqueValidation = false; // Reset trigger to false for future uses
      }, 0);
    };
  },
];
