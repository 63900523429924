import { IDialogService } from "../../common/services/IDialogService";

class AddToUnitDialogController {
    static $inject = ["independentLiving.service", "notification.service", "$state", "dialog.service", "modalConfig", "$q"];

    constructor(
        private readonly dialogService: IDialogService,
        private readonly modalConfig: any,
        private readonly $q: any
    ) {

    }
}
export = AddToUnitDialogController;