class SettleUnitDialogController {
    static $inject = ["modalConfig"];

    public $hide: any;
    public enquiryId: number;
    public residentId: number;

    constructor(readonly modalConfig: any) {
        this.enquiryId = modalConfig.enquiryId;
        this.residentId = modalConfig.residentId;
    }

    public bindHide = ($hide) => {
        this.$hide = $hide;
    };
}

export = SettleUnitDialogController;
