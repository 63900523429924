"use strict";

var moment = require("moment");

module.exports = [
    "residents.service",
    "$stateParams",
    "notification.service",
    "$state",
    "dialog.service",
    "$scope",
    "$rootScope",
    "$anchorScroll",
    "$location",
    "featureFlags.service",
    function (residentsService, $stateParams, notification, $state, dialogService, $scope, $rootScope,
        $anchorScroll, $location, featureFlagsService) {
        var vm = this;

        var residentId = $stateParams.residentId;

        vm.currentStateName = $state.current.name;

        // register watching state
        var unbindWatchHandler = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            vm.currentStateName = toState.name;
            if (toParams["#"]) {
                $location.hash(toParams["#"]);
                $anchorScroll();
            }
        });

        // unregister watching state
        $scope.$on('$destroy', function () {
            if (unbindWatchHandler) {
                unbindWatchHandler();
            }
        });

        vm.financialsTabIsActive = function () {
            switch (vm.currentStateName) {
                case "editResident.financialsFunding":
                case "editResident.financialsFees":
                case "viewResident.financialsFees":
                case "viewResident.financialsFunding":
                case "viewResident.financialsTransaction":
                    return true;
                default:
                    return false;
            }
        };

        Object.defineProperty(vm, "isHistoryTabOpen", {
            get: function () {
                return $state.current.name === "viewResident.history";
            }
        });

        function getResident(residentId) {
            residentsService.getResident(residentId).then(function (result) {
                vm.resident = result;

                if (vm.resident.dateOfBirth) {
                    vm.age = moment().diff(moment(vm.resident.dateOfBirth, "DD/MM/YYYY"), 'years');
                    vm.resident.dateofBirthFormatted = moment(vm.resident.dateOfBirth, "DD/MM/YYYY").format("DD MMM YYYY");
                }
                vm.showPostDepartureActions = !vm.resident.isTransferredToAnotherFacility && vm.resident.isDeparted && vm.resident.departureReason != "Deceased";
            }, function () {
                notification.error("Unable to display record. Either your computer is offline, or Resident Manager is having problems of its own.");
            });
        }

        vm.navigateToEdit = function () {
            $state.go("editResident", { residentId: residentId });
        };

        vm.navigateToMarkDepartedResidentAsEnquiry = function () {
            $state.go("markDepartedResidentAsEnquiry", { residentId: residentId });
        }

        getResident(residentId);

        vm.launchSendOnLeaveDialog = function () {
            dialogService.openDialog("app/residents/partials/sendOnLeaveDialog.html",
                "sendOnLeaveDialog.controller",
                {
                    resident: vm.resident
                });
        }

        vm.openDepartureDialog = function () {
            dialogService.openDialog("app/residents/partials/departureDialog.html",
                "departureDialog.controller",
                {
                    resident: vm.resident
                });
        }

        vm.launchReturnFromLeaveDialog = function () {
            dialogService.openDialog("app/residents/partials/returnFromLeaveDialog.html",
                "returnFromLeaveDialog.controller",
                {
                    resident: vm.resident
                });
        }

        vm.openTransferResidentDialog = function () {
            dialogService.openDialog("app/residents/partials/transferResidentDialog.html",
                "transferResidentDialog.controller",
                {
                    resident: vm.resident
                });
        }

        vm.openMakePrivateAsFundedDialog = function () {
            dialogService.openDialog("app/residents/partials/residentAdmissionDialog.html", "admitResidentDialog.controller", {
                residentId: residentId,
                isMakeResidentFunded: true
            });
        }
    }
];
