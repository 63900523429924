export class Constants {
  //#region BASE_64 image data when displaying no image
  static readonly NO_PHOTO_IMAGE: string =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAqCAYAAAGo+BlDAAAAAXNSR0IArs4c6QAABOxJREFUWAm9mFtoHFUYx7uzWfFak4aiUEK9oVhaqdAXUVEQUVBrUQwqaMzmpkL7IKUiKKK+VESlikiyuZgXxQh9UCqoIAUfDEWx9EIr8VKsij40qURKSJONv2/IOXv2zDezZzbBgeG7/b//+c5t5sysWbN8DQ4OHjV6ZBTkZgJLYrtOJ76sGpSYhaGhoeuXlpZ+dGEFMVzUwMBA7ItBZBww6BaDhML49Nbjkvr7+4sWhlJwG5GANBS1tLSsNahCobAoeq15DC3LJGR33aCQPvAXJ3a/o9ea9psVkNRHr+JxkLE8w0Csc7N9PYqiR/zO3AbLV62tres7Ozv/NQm2PprdhfMbmC+cmZmZRdqYVQDsM5kiKSUeN9FdkNjqZUHUcshFMBtbje0XbufcXSCWyWRpMmaiyNfpzR4fwBjd19fX93liefhAsYOai6RArk81BvyvxnEtKD7qnKLO65T4UySOK/7aqnGD2gpy41TyEavpcdcnelAf/SQqfsz3NU1GZV8Hk7W1tV2mgY2PLt5ldFfWLS83ILoydicZ/Bt9nLFVsuHh4Y2Li4unDMiTf1BZB12169rEzXJ9mkF93zjzymKx2N7b2zsty/oIyVvyEvh49sktsrJvInDGD+ax6XKFDTepjpkhYhe8h/4o9z8kvEbCmIlpUiUbHx/fMDc397uSUKUndW8HF6PugBQiyYsYY/uOdInioO+ga3f4Ps/e7NnW1CrbaaMpCsPQroUSZKy3qzWg61tYWFAxCTKSTrmJms7j/lfNnyBj8U1oQNfX1dWlrssEGWvpYzcxj54gk2QW6HQaCZWXU2NaoFQqbdD84svaBWpl3d3dc+S9qBBeovisSyVbjj5sUTUl8+mikrEL9pN/c43DapOVSuVBa3mK3eijo6PXsBgnWbTrPUyaeYAn7nYmq2oAMRmb9xyOi4wzp/yMJ8l2yZGnwHlks0TC8QDDslcUGbP4nCdGsxdD87zkqhPQLGnEAD7UbLLJ46V9seh2Nul3mXLfwne5ATWQ37G1nmRHnDA4S2YcjeTY2NiV8/Pz8o7d0QB7mvgzzLT9GGmArwsHFzYxMVHk1D1J9rY6hsZGldG4ldGQ3OAraGEwLRFF/QRr3qKkkKharX7L9N4TXJUkhYAhfQHcVSHYNAydq6TFNH9QYeyQu7XknL6OkZGRG0JzggqDLHHaDG3AxXGqDeYJLewHt4FmdEZ9lg0wFZobVBgnlLdDCTNw+yhudUesXC6fhnRXRqOZIXKP8zp8KRPkBYNGTHIgfhehHTs8yoT5MztSOxQkgK4j+AFrknhVy3+QgzSW+hVgsOA+oEPdxs4jcxcm5DzXdlLYOw0aOsbrKPPclZWfuzCKeoOidmeROrEpXke3sxv/dnxBamZhTFuJD9t7eaV0wbYjZPrSWmVa5eQwzHn7Q47Jf6XhjL+uMAq5FoJXKEB+ewRvDEOWV0qxtPUyU/6JnxsXxvQ8B+BNP/h/2hT5PT8W7zQ/FuXXg/wnbPoZtcrFy9/OdYzgeZmuJ1aZfCV0l5IcH0DlbP0shv1AWQnrKuR+yTl9v/DYxc+n2RWss93cffhDz+orroWB+YJ7L4+Ugy6ZLcx1Gp0iI85Qm5BbuDfh38jdgd4OWRtyLfICfCXuCHseKR9x5/CfRU7j+xP5G/YU91Gea4d7enpm8WVe/wGglJEbAzviJwAAAABJRU5ErkJggg==";
  //#endregion

  //#regin when showing statement some fields not required after july 2024
  static readonly JULY_2024: number = 202407;
  //#endregion
}
